import React, { useEffect } from 'react';

const ThemeSwitcher = ({ darkMode, toggleDarkMode }) => {
  // Remove the useState declaration for darkMode and toggleDarkMode

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', String(darkMode));
  }, [darkMode]);


  return (
    <label className="relative inline-flex items-center cursor-pointer ml-2">
    <input 
      type="checkbox" 
      value="" 
      className="sr-only peer" 
      checked={darkMode}
      onChange={toggleDarkMode}
    />
    <div className={`w-11 h-6 rounded-full peer after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full focus:outline-none focus:ring-0 ${darkMode ? 'bg-purple-700 dark:border-purple-600 peer-checked:after:border-white' : 'bg-yellow-200 peer-focus:outline-none peer-focus:ring-4 after:bg-white'}`}></div>    </label>
  );
};

export default ThemeSwitcher;