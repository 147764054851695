import React, { useState, useEffect } from 'react';
import { auth, firestore} from '../firebase';
import { collection, doc, setDoc, addDoc } from "firebase/firestore"; 

function CarInterventionModal({ clientId, carId, isModalOpen, setIsModalOpen, setRefreshKey }) {
  
    const [interventions, setInterventions] = useState([{ details: '', price: '' }]);
    const [date, setDate] = useState('');    
    const [values, setValues] = useState({});
    const [interventionType, setInterventionType] = useState('');

    const handleTypeChange = (e) => {
      setInterventionType(e.target.value);
    };
    useEffect(() => {
      setDate(new Date().toISOString().substr(0, 10));
  }, []);
 
  if (!isModalOpen) {
    return null;
  }


  const addIntervention = async (userUid, clientId, carId, interventionType, interventionData) => {
    try {
      await setDoc(doc(collection(firestore, 'customers', userUid, 'clients', clientId, 'car', carId, 'interventions', interventionType)), interventionData);
      console.log('Intervention added successfully');
    } catch (error) {
      console.error('Error adding intervention: ', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const user = auth.currentUser;
    const userUID = user.uid;
  
    // Construct the Firestore path
    const firestorePath = `customers/${userUID}/clients/${clientId}/car/${carId}/interventions`;
    console.log (firestorePath);
    // Collect form data
    const formData = new FormData(e.target);
    const date = formData.get('date');
    const interventionType = formData.get('interventionType');
  
    // Prepare data object
    let data = {
      date: date,
      interventionType: interventionType
    };
  
    // Add additional data based on the intervention type
    if (interventionType === 'itp') {
      const endDate = formData.get('endDate');
      const pret = formData.get('pret');
      data = { ...data, endDate: endDate, pret: pret };
    } else if (interventionType === 'schimb') {
      const denumire = formData.get('denumire');
      const km = formData.get('km');
      const pret = formData.get('pret');
      data = { ...data, denumire: denumire, km: km, pret: pret };
    } else if (interventionType === 'elseInt') {
      // Handle dynamic intervention data fields
      interventions.forEach((intervention, index) => {
        const denumire = formData.get(`denumire${index}`);
        const um = formData.get(`um${index}`);
        const cantitate = formData.get(`cantitate${index}`);
        const pretUnitar = formData.get(`pretUnitar${index}`);
        const valoare = formData.get(`valoare${index}`);
        const valtotal = formData.get(`valtotal${index}`);
        data = { ...data, denumire, um, cantitate, pretUnitar, valoare, valtotal };
      });
    }
    
    // Split the Firestore path
    const firestorePathSegments = firestorePath.split('/');

    try {
      // Add data to Firestore
      await addDoc(collection(firestore, ...firestorePathSegments), data);
      alert ('Interventia a fost adaugata cu succes!');
      // Close the modal
      setIsModalOpen(false);
      // Refresh the data
      setRefreshKey((prev) => prev + 1);
      console.log('Data added to Firestore successfully!');
    } catch (error) {
      console.error('Error adding data to Firestore:', error);
    }
  };
const handleChange = (index, field) => (event) => {
  const val = event.target.value;
  setValues(prev => {
    const newVal = { ...prev };
    newVal[`${field}${index}`] = val;

    if (field === 'cantitate' || field === 'pretUnitar') {
      const cantitate = newVal[`cantitate${index}`] || 0;
      const pretUnitar = newVal[`pretUnitar${index}`] || 0;
      newVal[`valoare${index}`] = cantitate * pretUnitar;
      newVal[`valtotal${index}`] = (newVal[`valoare${index}`] * 1.19).toFixed(2);
    }

    return newVal;
  });
};
    return (
      <div className="fixed left-0 right-0 z-50 bg-slate-800/80 h-full pb-10 overflow-y-auto top-0 md:inset-0 " id="editClientModal" aria-hidden="true"> 
      <div class="relative md:h-auto  flex justify-center items-center content-center mt-20 w-full ">
         {/* Modal content */}
         <div className=" bg-white w-1/2 rounded-lg shadow dark:bg-gray-800 xs:w-full md:w-full">
           {/* Modal header */}
           <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
               <h3 className="text-xl font-semibold dark:text-white">
                 Adaugă interventie
               </h3>
               <button type="button"   onClick={() => setIsModalOpen(false)} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                   <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                 </svg>
               </button>
             </div>
             <div className="p-6 space-y-6">
    <form id="AddInterventionForm" onSubmit={handleSubmit}>
        <div className="flex gap-2 flex-col mb-4 items-center">
            <div className="col-span-6 w-1/2">
                <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Data:</label>
                <input type="date" id="date" name="date" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"  required />
            </div>
            <div className="col-span-6 w-1/2">
          <label htmlFor="interventionType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tipul interventiei:</label>
          <select id="interventionType" name="interventionType" onChange={handleTypeChange} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required>
            <option value="">Selecteaza</option>
            <option value="itp">ITP</option>
            <option value="schimb">Schimb de ulei</option>
            <option value="elseInt">Alte interventii</option>
          </select>
        </div>

        {interventionType === 'itp' && (
        <React.Fragment>
          
         <div className="col-span-6 w-1/2">
                <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pana la:</label>
                <input type="date" id="endDate" name="endDate" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
          </div>
          <div className="col-span-6 w-1/2">
                <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pret:</label>
                <input type="number" id="pret" name="pret" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
          </div>
        </React.Fragment>
        )}

        {interventionType === 'schimb' && (
          <React.Fragment>
            <div className="col-span-6 sm:col-span-3 w-1/2">
              <label htmlFor="denumire" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Denumire:</label>
              <input type="text" id="denumire" name="denumire" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
            </div>
            <div className="col-span-6 sm:col-span-3 w-1/2">
              <label htmlFor="denumire" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">KM:</label>
              <input type="number" id="denumire" name="denumire" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
            </div>
            <div className="col-span-6 sm:col-span-3 w-1/2">
              <label htmlFor="pret" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pret:</label>
              <input type="number" id="pret" name="pret" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
            </div>
          </React.Fragment>
        )}
        {interventionType === 'elseInt' && interventions.map((intervention, index) => (
           <React.Fragment key={index}>
           <div className="flex flex-row gap-4 xs:flex-col md:flex-col"> 
          <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`denumire${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Denumire</label>
         <input type="text" name={`denumire${index}`} id={`denumire${index}`} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`um${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">U.M.</label>
         <input type="text" name={`um${index}`} id={`um${index}`} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`cantitate${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cantitate</label>
         <input type="number" name={`cantitate${index}`} id={`cantitate${index}`} onChange={handleChange(index, 'cantitate')} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`pretUnitar${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Preț Unitar</label>
         <input type="number" name={`pretUnitar${index}`} id={`pretUnitar${index}`} onChange={handleChange(index, 'pretUnitar')}  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`valoare${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Valoare</label>
         <input type="number" name={`valoare${index}`} id={`valoare${index}`} value={values[`valoare${index}`] || ''} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       <div className="col-span-6 sm:col-span-3">
         <label htmlFor={`valoare${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Total cu TVA</label>
         <input type="number" name={`valtotal${index}`} id={`valtotal${index}`} value={values[`valtotal${index}`] || ''} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required />
       </div>
       </div>
      
         </React.Fragment>
         
        ))}
            {interventionType === 'elseInt'  && (
  <div className="col-span-6">
    <button type="button" className="text-bold mb-2 text-3xl text-white" onClick={addIntervention}>+</button>
  </div>
)}
</div>

<div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
  <button 
    onClick = {() =>  handleSubmit}
    className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>
</div>
</form>
</div>
</div>
</div>
</div>
);

}

export default CarInterventionModal;
