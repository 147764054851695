import React, { useState, useEffect } from "react";
import { getDatabase, ref, get, remove} from "firebase/database";
import {
  startOfWeek,
  addDays,
  format,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  differenceInDays,
  isSameMonth,
} from "date-fns";
import { auth } from "../firebase";
import { firestore } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import carList from './car-list.json';
import { ro } from 'date-fns/locale';
import { httpsCallable } from 'firebase/functions';
import { functions, db} from '../firebase';


const Calendar2 = (props) => {
  const [currentStartOfWeek, setCurrentStartOfWeek] = useState(
    startOfWeek(new Date())
  );
  const [view, setView] = useState("week");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);


  useEffect(() => {
    const fetchEmployees = async () => {
      const { uid } = auth.currentUser;
      const q = query(
        collection(doc(collection(firestore, "customers"), uid), "employees")
      );
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map((doc) => doc.data());

      localStorage.setItem('employees', JSON.stringify(fetchedEmployees));

      setEmployees(fetchedEmployees);

      if (fetchedEmployees.length === 0) {
        alert('Vă rugăm să adăugați un mecanic în pagina de setări.');
      }
    };
      fetchEmployees();
  }, []);



  useEffect(() => {
    const fetchAppointments = async () => {
      const user = auth.currentUser;

      if (user) {
        const appointmentsRef = collection(
          doc(collection(firestore, "customers"), user.uid),
          "appointments"
        );

        const querySnapshot = await getDocs(appointmentsRef);
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        console.log("Fetched appointments:", data);
        setAppointments(data);

        const unsubscribe = onSnapshot(
          appointmentsRef,
          (snapshot) => {
            const newAppointments = snapshot.docChanges().filter(({type}) => type === 'added').map(({doc}) => ({
              ...doc.data(),
              id: doc.id,
            }));

            if (newAppointments.length > 0) {
              const updatedAppointments = [...appointments, ...newAppointments];
              setAppointments(updatedAppointments);
            }
          }
        );

        return () => unsubscribe();
      }
    };

    fetchAppointments();
  }, []);
  const handleDay2Click = (day, hour) => {
    const appointment = appointments.find(
      (appointment) =>
        appointment.date === format(day, "yyyy.MM.dd") &&
        appointment.hour.split(":")[0] === hour.split(":")[0]
    );

    console.log("Day:", format(day, "yyyy.MM.dd"));
    console.log("Hour:", hour.split(":")[0]);
    console.log("Selected appointment:", appointment);

    setSelectedAppointment(appointment);

    setIsAppointmentModalOpen(true);
  };
  const handleDeleteAppointment = async (appointment) => {
    const user = auth.currentUser;
    if (appointment && appointment.id) {
      if (appointment.type === 'blocat') {
        // If the appointment type is 'block', delete it from Firebase Realtime Database
        const db = getDatabase();
        const appointmentRef = ref(db, `customers/${user.uid}/blocked/${appointment.id}`);
        await remove(appointmentRef);
      } else {
        // Otherwise, delete it from Firestore as usual
        const docRef = doc(firestore, "customers", user.uid, "appointments", appointment.id);
        await deleteDoc(docRef);
      }
  
      setSelectedAppointment(null);
      setIsAppointmentModalOpen(false);
      props.setRefreshKey(oldKey => oldKey + 1);
    } else {
      console.error('No appointment selected or appointment ID is missing');
    }
  };
  const handleDayClick = (day, hourRange) => {
    console.log("Date clicked:", day);
    console.log("Hour clicked:", hourRange);

    setIsModalOpen(true);
    setSelectedHour(hourRange);
    setSelectedDate(format(day, "yyyy.MM.dd"));
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setSelectedAppointment(null);
  };
  const renderDays = () => {
    const dateFormat = "d";
    const days = [];

    let startDate;
    let numberOfDays;

    if (view === "week") {
      startDate = currentStartOfWeek;
      numberOfDays = 7;
    } else {
      const currentMonth = new Date();
      startDate = startOfWeek(startOfMonth(currentMonth));
      const endDate = endOfWeek(endOfMonth(currentMonth));
      numberOfDays = differenceInDays(endDate, startDate) + 1;
    }

    for (let i = 0; i < numberOfDays; i++) {
      const day = addDays(startDate, i);
      const isCurrentMonth = isSameMonth(day, new Date());
      const weekDays = ["Dum", "Lun", "Mar", "Mie", "Joi", "Vin", "Sâm"];
      const displayDay =
        view === "week" ? weekDays[day.getDay()] : format(day, dateFormat);
      const displayDate = format(day, "yyyy.MM.dd");

      days.push(
        <div
          key={i}
          className={`grid grid-rows-24 gap-2 ${
            isCurrentMonth ? "" : "text-gray-400"
          } ${view === "week" ? "sm:text-base md:text-lg lg:text-xl" : ""}`}
        >
          <div
            className={`p-2 text-black dark:text-white font-bold bg:text-white self-center text-center ${
              view === "week" ? "sm:text-base md:text-lg lg:text-xl" : ""
            }`}
            onClick={isCurrentMonth ? () => handleDayClick(day) : undefined}
          >
            {displayDay}
            {view === "week" && <div>{displayDate}</div>}
          </div>
          {view === "week" && renderHours(day)}
        </div>
      );
    }

    return (
    <div
        className={`flex flex-col-reverse sm:grid ${
            view === "week"
                ? " text-white grid-cols-1 border border-gray-900 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7"
                : "sm:grid-cols-7"
        } w-full`}
    >
        {days}
    </div>
    );
  };

  const [startHour, setStartHour] = useState(null);
  const [endHour, setEndHour] = useState(null);
const [selectedHours, setSelectedHours] = useState([]);
const [isButtonActive, setButtonActive] = useState(false);
  useEffect(() => {
    const fetchHours = async () => {
      const user = auth.currentUser;

      if (user) {
        const storedHours = JSON.parse(localStorage.getItem('hours'));

        if (storedHours) {
          console.log('Fetching hours from local storage');
          setStartHour(storedHours.startHour);
          setEndHour(storedHours.endHour);
        } else {
          console.log('Fetching hours from Firestore');
          const userRef = doc(collection(firestore, "customers"), user.uid);

          const docSnapshot = await getDoc(userRef);
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();

            localStorage.setItem('hours', JSON.stringify({ startHour: data.startHour, endHour: data.endHour }));

            setStartHour(data.startHour);
            setEndHour(data.endHour);
            console.log("Fetched hours:", data);
          }
        }
      }
    };

    fetchHours();
  }, []);

  const [blockedAppointments, setBlockedAppointments] = useState([]);
  useEffect(() => {

  const fetchBlockedAppointments = async () => {
    const user = auth.currentUser;
    console.log('User:', user.uid); // Print the user object
  
    if (user) {
      const db = getDatabase();
      const blockedAppointmentsRef = ref(db, `customers/${user.uid}/blocked`);
  
      try {
        const snapshot = await get(blockedAppointmentsRef);
        const data = [];
        snapshot.forEach((childSnapshot) => {
          data.push({
            ...childSnapshot.val(),
            id: childSnapshot.key,
          });
        });
  
        setBlockedAppointments(data);
        console.log("Fetched blocked appointments:", data);
      } catch (error) {
        console.error('Error fetching blocked appointments:', error); // Print any errors
      }
    }
  };
  
  fetchBlockedAppointments();

}, []);
const handleBlockedAppointmentClick = (day, hourRange) => {
  console.log('handleBlockedAppointmentClick called');

  const dayString = day.toLocaleDateString('en-CA'); // Convert day to YYYY-MM-DD format
  const startHour = hourRange.split('-')[0]; // Extract start hour from hourRange

  console.log('dayString:', dayString);
  console.log('startHour:', startHour);

  const blockedAppointment = blockedAppointments.find(appointment => 
    appointment && appointment.date && appointment.date.replace(/\./g, '-') === dayString && appointment.hour === startHour
  );

  console.log('blockedAppointment:', blockedAppointment);

  if (blockedAppointment) {
    setSelectedAppointment(blockedAppointment);
    setIsAppointmentModalOpen(true);
  } else {
    console.log('No blocked appointment found for this day and hour range');
  }
};
   const renderHours = (day) => {
    if (startHour === null || endHour === null) {
      return null;
    }

    const start = parseInt(startHour.split(":")[0]);
    const end = parseInt(endHour.split(":")[0]);

    const hours = [];
    for (let i = start; i < end; i++) {
      const startHour = String(i).padStart(2, "0");
      const endHour = String(i + 1).padStart(2, "0");
      const hourRange = `${startHour}:00-${endHour}:00`;
    
      const appointment = appointments.find((appointment) => {
        const appointmentDate = new Date(appointment.date.replace(/\./g, "/"));
        appointmentDate.setHours(0, 0, 0, 0);
        const dayDate = new Date(day);
        dayDate.setHours(0, 0, 0, 0);
        const appointmentHour =
          appointment && appointment.hour
            ? Number(appointment.hour.split(":")[0])
            : 0;
        return (
          appointmentDate.getTime() === dayDate.getTime() &&
          appointmentHour >= i &&
          appointmentHour < i + 1 &&
          appointment.employeeTag === selectedEmployee
        );
      });
const appointmentBlocked = blockedAppointments.find((blockedAppointment) => {
  const appointmentDate = blockedAppointment.date 
  ? new Date(blockedAppointment.date.replace(/\./g, "/"))
  : new Date();  appointmentDate.setHours(0, 0, 0, 0);
  const dayDate = new Date(day);
  dayDate.setHours(0, 0, 0, 0);
  const appointmentHour = blockedAppointment && blockedAppointment.hour
    ? Number(blockedAppointment.hour.split(":")[0])
    : 0;

  return (
    blockedAppointment.type === 'blocat' &&
    appointmentDate.getTime() === dayDate.getTime() &&
    appointmentHour === i && 
    blockedAppointment.employeeTag === selectedEmployee
  );
});
      const dayDate = new Date(day);
      dayDate.setHours(0, 0, 0, 0);

      const isSelected = selectedHours.some(selectedHour => 
        selectedHour.day.getTime() === dayDate.getTime() &&
        selectedHour.hourRange === hourRange &&
        selectedHour.employeeTag === selectedEmployee
      );
      hours.push(
        <div
          key={i}
          className={`p-2 m-1 text-center ${
            appointmentBlocked ? "bg-red-900" : 
            appointment ? "bg-red-600 cursor-pointer" : "bg-green-600"
          } ${isPastDate(day) && !appointment ? "opacity-50 pointer-events-none " : 
            isButtonActive && isSelected ? "opacity-50 cursor-pointer" : "cursor-pointer"}`}
          onClick={() => {
            console.log('Hour clicked'); 
            console.log('day:', day); 
            console.log('hourRange:', hourRange); 
            console.log('employeeTag:', selectedEmployee); 
            if (isButtonActive && (!isPastDate(day) || appointment)) {
              const newSelectedHours = [...selectedHours];
              const hourIndex = newSelectedHours.findIndex(selectedHour => 
                selectedHour.day.getTime() === day.getTime() &&
                selectedHour.hourRange === hourRange &&
                selectedHour.employeeTag === selectedEmployee
              );
              if (hourIndex > -1) {
                newSelectedHours.splice(hourIndex, 1);
              } else {
                newSelectedHours.push({ day, hourRange, employeeTag: selectedEmployee });
              }
              setSelectedHours(newSelectedHours);
            } else if (!isButtonActive) {
              console.log('appointmentBlocked:', appointmentBlocked);
              appointmentBlocked
                ? handleBlockedAppointmentClick(day, hourRange)
                : appointment
                  ? handleDay2Click(day, hourRange)
                  : handleDayClick(day, hourRange)
            }
            
            
          }}
        >
          {hourRange}
        </div>
      );
      function isPastDate(date) {
        const currentDate = new Date();
        const appointmentDate = new Date(date);
        return appointmentDate.setHours(0,0,0,0) < currentDate.setHours(0,0,0,0);
      }
    }
    return hours;
  };
  const nextWeek = () => {
    setCurrentStartOfWeek((prevState) => addDays(prevState, 7));
  };
  const prevWeek = () => {
    setCurrentStartOfWeek((prevState) => addDays(prevState, -7));
  };

  const dateFormat = "MMMM yyyy";

  const [selectedOption, setSelectedOption] = useState(null);

  function NewClientAppForm({ onSubmit }) {
    const [clientType, setClientType] = useState('');

    const handleSelectChange = (event) => {
      setClientType(event.target.value);
    };
    const formattedHour =
      selectedHour < 10 ? `0${selectedHour}:00` : `${selectedHour}`;
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
      const fetchEmployees = async () => {
        const user = auth.currentUser;
        const employeesRef = collection(
          doc(collection(firestore, "customers"), user.uid),
          "employees"
        );

        const employeeSnapshot = await getDocs(employeesRef);

        const employees = employeeSnapshot.docs.map((doc) => ({
          employeeTag: doc.data().employeeTag,
          ...doc.data(),
        }));
        setEmployees(employees);
      };

      fetchEmployees();
    }, []);
 
    const yourSubmitHandler = async (event) => {
      event.preventDefault();

      const clientType = event.target.elements["clientType"].value;
      const lastName = event.target.elements["lastName1"].value;
      const firstName = event.target.elements["firstName1"].value;
      const phone = event.target.elements["nrTel"].value;
      const employeeTag = event.target.elements["employee"].value;
      const email = event.target.elements["email"].value;
      const position = event.target.elements["position"].value;
      const model = event.target.elements.model.value;
      const marca = event.target.elements.marca.value;
      const description = marca + " " + model;
      const cnpcui = clientType === 'Persoana Juridica' ? event.target.elements["cnpcui"].value : null;

      const clientData = {
        email,
        firstName,
        lastName,
        phone,
        clientType,
        adresaOficiala: clientType === 'Persoana Juridica' ? adresaOficiala : null,
        denumireOficiala: clientType === 'Persoana Juridica' ? fetchedData : null,
      };

      if (clientType === 'Persoana Juridica') {
        clientData.cnpcui = cnpcui;
      }

      const carData = {
        position,
        description,
      };

      const appointmentData = {
        date: selectedDate,
        hour: selectedHour ? selectedHour.split("-")[0] : "",
        employeeTag: employeeTag,
      };

      const data = {
        clientData,
        carData,
        appointmentData,
      };

      try {
        const clientOperations = httpsCallable(functions, 'clientOperations');
        const result = await clientOperations({ operation: 'add', data: data });
        console.log(result.data);
        alert("Programarea a fost adaugata cu succes!");
        setIsModalOpen(false); 
        props.setRefreshKey(oldKey => oldKey + 1);
      } catch (error) {
        console.error("Error calling clientOperations function: ", error);
      }
    };

    const [inputValue, setInputValue] = useState('');
    const [selectedBrand, setSelectedBrand] = useState(null);
  
    const filteredCarList = carList.filter(car => 
      car.brand.toLowerCase().startsWith(inputValue.toLowerCase())
    );
  
    const handleBrandChange = e => {
      setInputValue(e.target.value);
      setSelectedBrand(carList.find(car => car.brand === e.target.value));
    };

    const [cnpcui, setCnpCui] = useState('');
    const [fetchedData, setFetchedData] = useState('');
    const adresaOficiala = useState('')[0];
    const fetchData = async () => {
      if (cnpcui) {
        try {
            let cui = cnpcui;

            const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "cui": cui })
            });

            if (!response.ok) {
              alert('CUI-ul introdus nu este valid');

                throw new Error('Network response was not ok');

            }

            const data = await response.json();
            data.adresaOficiala = data.address;
            setFetchedData(data.denumire);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    };
    return (
      <form
        id="newClientApp"
        onSubmit={yourSubmitHandler}
        className="grid grid-cols-6 gap-6 mb-5"
      >
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="clientType"
            className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
              
            Tip<span className="text-white text-xs opacity-50"> * obligatoriu</span>
          </label>
          <select
            name="clientType"
            id="clientType1"
            onChange={handleSelectChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            required
          >
            <option value="Persoana Fizica">Persoana Fizica</option>
            <option value="Persoana Juridica">Persoana Juridica</option>
          </select>
        </div>
 {clientType === 'Persoana Juridica' && (
  <div className="col-span-6 sm:col-span-3">
    <label
      htmlFor="add"
      className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
      CUI
      <span className="text-white text-xs opacity-50"> * obligatoriu</span>
    </label>
    <input
      type="text"
      id="cnpcui"
      name="cnpcui"
      placeholder="RO12345678"
      onChange={(e) => setCnpCui(e.target.value)}
      onBlur={fetchData}
      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
      required
    />
  </div>
)}
{clientType === 'Persoana Juridica' && (
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="first-name" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                      Denumire Firma
                      <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                    </label>
                    <input type="text" name="first-name" id="denumire" value={fetchedData} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Auto" required />
                  </div>
                )}
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="first-name"
            className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
  {clientType === 'Persoana Juridica' ? 'Prenume Reprezentant' : 'Prenume'}
  <span className="text-white text-xs opacity-50"> * obligatoriu</span>
          </label>
          <input
            type="text"
            name="first-name"
            id="firstName1"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            placeholder="Ion"
            required
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="last-name"
            className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
              {clientType === 'Persoana Juridica' ? 'Nume Reprezentant' : 'Nume'}
              <span className="text-white text-xs opacity-50"> * obligatoriu</span>
          </label>
          <input
            type="text"
            name="last-name"
            id="lastName1"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            placeholder="Popescu"
            required
          />
        </div>
        
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="add"
            className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
            Nr. Înmatriculare
            <span className="text-white text-xs opacity-50"> * obligatoriu</span>
          </label>
          <input
            type="text"
            id="position"
            name="position"
            placeholder="B000EXE"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            required
          />
        </div>
  
        <div className="col-span-6 sm:col-span-3 ">
                  <label htmlFor="marca" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                    Marcă
                  <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                  </label>
                  <input 
                  id="marca" 
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                  placeholder="Renault"
                  value={inputValue}
                  onChange={handleBrandChange}
                  list="car-brands"
                  required
                />
                <datalist id="car-brands">
                  {filteredCarList.map((car, index) => (
                    <option key={index} value={car.brand} />
                  ))}
                </datalist>
               </div>
               <div className="col-span-6 sm:col-span-3">
               <>
              <label htmlFor="model" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                Model
              <span className="text-white text-xs opacity-50"> * obligatoriu</span>
              </label>
              <input 
                id="model" 
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                placeholder="Megane"
                list="car-models"
                required
              />
              <datalist id="car-models">
                {selectedBrand && selectedBrand.models.map((model, index) => (
                  <option key={index} value={model} />
                ))}
              </datalist>
            </>
              </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="nrTel"
            className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
            Telefon
            <span className="text-white text-xs opacity-50"> * obligatoriu</span>
          </label>
          <input
            type="text"
            id="nrTel"
            name="nrTel"
            placeholder="0712345678"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            required
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Email:
          </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="adresa@exemplu.ro"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
            
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="ora"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Ora
          </label>
          <div className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500">
            {" "}
            {formattedHour}{" "}
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="data"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Data
          </label>
          <div
            id="data"
            name="data"
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          >
            {selectedDate}
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="employee"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Mecanic
          </label>
          <select
            id="employee"
            name="employee"
            value={selectedEmployee}
            
            disabled
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"
          >
              {employees.map((employee) => (
            <option key={employee.employeeTag} value={employee.employeeTag}>
              {employee.employeeName} {employee.employeeLast}
            </option>
          ))}
          </select>
        </div>
        <div className="items-center pt-6 col-span-6">
          <button
            className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
            type="submit"
          >
            Programare
          </button>
        </div>
      </form>
    );
  }

  function CurrentClientForm({ onSubmit }) {
    const [clients, setClients] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedClient, setSelectedClient] = useState("");

    const yourSubmitHandlerForCurrentClients = async (event) => {
      event.preventDefault();

      console.log("selectedClient in submit handler:", selectedClient);

      if (!selectedClient) {
        alert("Please select a client");
        return;
      }

      const formattedHour = selectedHour ? selectedHour.split("-")[0] : "";

      const appointmentData = {
        date: selectedDate,
        employeeTag: selectedEmployee,
        firstName: selectedClient.firstName,
        lastName: selectedClient.lastName,
        phone: selectedClient.phone,
        hour: formattedHour,
        position: selectedCar.position,
        clientID: selectedClient.id,
      };
     
  try {
    const clientOperations = httpsCallable(functions, 'clientOperations');
    const result = await clientOperations({ operation: 'add', data: appointmentData });
    console.log(result.data);
    alert("Programarea a fost adaugata cu succes!");
    setIsModalOpen(false); 
    props.setRefreshKey(oldKey => oldKey + 1);
  } catch (error) {
    console.error("Error calling clientOperations function: ", error);
  }

    };
    useEffect(() => {
      const fetchClientsAndEmployees = async () => {
        const user = auth.currentUser;

        const clientsRef = collection(
          firestore,
          "customers",
          user.uid,
          "clients"
        );
        const clientsSnapshot = await getDocs(clientsRef);
        const clientsData = await Promise.all(
          clientsSnapshot.docs.map(async (doc) => {
            const clientData = doc.data();
            const carRef = collection(
              firestore,
              "customers",
              user.uid,
              "clients",
              doc.id,
              "car"
            );
            const carSnapshot = await getDocs(carRef);
            const carData = carSnapshot.docs.map((carDoc) => carDoc.data());
            return { id: doc.id, ...clientData, car: carData };
          })
        );

        const employeesRef = collection(
          doc(collection(firestore, "customers"), user.uid),
          "employees"
        );
        const employeesSnapshot = await getDocs(employeesRef);
        const employeesData = employeesSnapshot.docs.map((doc) => doc.data());

        setClients(clientsData);
        setEmployees(employeesData);
      };

      fetchClientsAndEmployees();
    }, []);

    const handleClientChange = (event) => {
      const selectedClientId = event.target.value;
      const selectedClient = clients.find(
        (client) => String(client.id) === selectedClientId
      );
      setSelectedClient(selectedClient);
      console.log("Selected client:", selectedClient);
    };
    useEffect(() => {}, [selectedClient]);
    const handleEmployeeChange = (e) => {
      setSelectedEmployee(e.target.value || "");
    };
    const [selectedCar, setSelectedCar] = useState(null);

    const handleCarChange = (event) => {
      const selectedCarIndex = event.target.value;
      const selectedCar = selectedClient
        ? selectedClient.car[selectedCarIndex]
        : null;
      setSelectedCar(selectedCar);
      console.log("Selected car:", selectedCar);
    };

    return (
      <form onSubmit={yourSubmitHandlerForCurrentClients} className="space-y-4">
        <div className="col-span-6 sm:col-span-3">
          <div className="p-5">
            <label
              htmlFor="client"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Client:
            </label>
            <select
              id="client"
              onChange={handleClientChange}
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
            >
              <option value="">Alege un client</option>
              {clients.map((client) => (
                <option key={client.id} value={client.id}>
                  {client.firstName} {client.lastName}
                </option>
              ))}
            </select>
          </div>
          {selectedClient && (
            <div className="p-5">
              <label
                htmlFor="car"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Masina:
              </label>
              <select
                id="car"
                onChange={handleCarChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
              >
                <option value="">Alege o masina</option>
                {selectedClient.car.map((car, index) => (
                  <option key={index} value={index}>
                    {car.description} - {car.position}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="p-5">
            <label
              htmlFor="employee"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Mecanic:
            </label>
            <select
  id="employee"
  name="employee"
  value={selectedEmployee}
  onChange={handleEmployeeChange}
  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500"
  disabled
>
  {employees.map((employee) => (
    <option key={employee.employeeTag} value={employee.employeeTag}>
      {employee.employeeName} {employee.employeeLast}
    </option>
  ))}
</select>
          </div>
        </div>

        <div className="items-center pl-5 pt-6 col-span-6">
          <button
            type="submit"
            disabled={!selectedClient || !selectedCar}
            className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
          >
            Programare
          </button>
        </div>
      </form>
    );
  }

  useEffect(() => {
    if (employees.length > 0) {
      setSelectedEmployee(employees[0].employeeTag);
    }
  }, [employees]);
  useEffect(() => {
    console.log('isButtonActive updated:', isButtonActive);
  }, [isButtonActive]);
  const currentEndOfWeek = addDays(currentStartOfWeek, 6);
  const currentStartOfWeekFormatted = currentStartOfWeek.toLocaleDateString('ro-RO');
  const currentEndOfWeekFormatted = currentEndOfWeek.toLocaleDateString('ro-RO');

  useEffect(() => {
    if (isAppointmentModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isAppointmentModalOpen]);
  return (
    <div className="p-4 bg-gray-100 dark:bg-slate-900 rounded-lg shadow-md">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <button
          onClick={prevWeek}
          className="px-4 py-2 bg-purple-700 text-white rounded-lg mb-2 sm:mb-0"
        >
          Anterior
        </button>
        <div className="mb-2 sm:mb-0">
        {`${format(currentStartOfWeek, dateFormat, { locale: ro }).charAt(0).toUpperCase()}${format(currentStartOfWeek, dateFormat, { locale: ro }).slice(1)}`}
        </div>
        <button
          onClick={nextWeek}
          className="px-4 py-2 bg-purple-700 text-white rounded-lg mb-2 sm:mb-0"
        >
          Următorul
        </button>
        <div
          className="px-4 py-2 bg-purple-700 text-white rounded-lg mb-2"
        >
          {currentStartOfWeekFormatted} - {currentEndOfWeekFormatted}
        </div>
        <div
          className={`px-4 py-2 rounded-lg cursor-pointer mb-2 ${isButtonActive ? "bg-green-700" : "bg-purple-700"}`}
          onClick={async () => {
            if (isButtonActive) {
              const dayObject = {};
          
              selectedHours.forEach(({ day, hourRange, employeeTag }) => {
                const formattedDay = `${day.getFullYear()}.${("0" + (day.getMonth() + 1)).slice(-2)}.${("0" + day.getDate()).slice(-2)}`;
                const firstHour = hourRange.split('-')[0].trim();
                if (!dayObject[employeeTag]) {
                  dayObject[employeeTag] = {};
                }
                if (!dayObject[employeeTag][formattedDay]) {
                  dayObject[employeeTag][formattedDay] = [];
                }
                dayObject[employeeTag][formattedDay].push(firstHour);
              });
          
              console.log(dayObject);
          
              const blocareCalendar = httpsCallable(functions, 'blocareCalendar');
              try {
                const result = await blocareCalendar(dayObject);
                console.log(result); 
                props.setRefreshKey(oldKey => oldKey + 1);
                alert('Calendarul a fost blocat cu succes!');

              } catch (error) {
                console.error('Error:', error);
              }
            } else {
              setSelectedHours([]);
            }
          
            setButtonActive(!isButtonActive);
          }}
        >
          {isButtonActive ? "Salveaza" : "Blocheaza calendarul"}
        </div>
        <select
          value={selectedEmployee}
          onChange={(e) => setSelectedEmployee(e.target.value)}
          className="px-4 py-2 bg-purple-700 text-white rounded-lg"
        >
          <option value="">Alege un mecanic</option>
          {employees.map((employee, index) => (
            <option key={index} value={employee.employeeTag}>
              {employee.employeeName} {employee.employeeLast}
            </option>
          ))}
        </select>
      </div>

      {(selectedEmployee || employees.length === 0) && renderDays()}
      {isModalOpen && (
        <div
          className={`fixed left-0 right-0 z-50 items-center justify-center overflow-x-hidden top-0 md:inset-0 h-modal sm:h-full bg-slate-900/80 inline-flex ${
            isModalOpen ? "block" : "hidden"
          }`}
          id="add-user-modal"
          aria-hidden="true"
        >
          <div className="relative bg-white p-10 rounded-lg shadow dark:bg-gray-800">
            <button
              type="button"
              className=" text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white float-end"
              onClick={closeModal}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                Programare pentru: {selectedHour} , {selectedDate}
              </h3>
            </div>
            <div className="p-5">
              <label
                htmlFor="clientType"
                className="block text-sm font-medium text-gray-700 dark:text-white"
              >
                Tipul clientului
              </label>
              <select
                id="clientType"
                name="clientType"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-700 focus:border-purple-700 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-700 dark:focus:border-purple-700"
              >
                <option>Selectează clientul</option>
                <option value="CurrentClient">Client existent</option>
                <option value="NewClient">Client nou</option>
              </select>
            </div>
            {selectedOption === "NewClient" && <NewClientAppForm />}
            {selectedOption === "CurrentClient" && <CurrentClientForm />}
          </div>
        </div>
      )}
    {isAppointmentModalOpen && selectedAppointment && (
        <div
            className="fixed inset-0 flex items-center justify-center z-50 dark:bg-slate-900/80"
            onClick={handleCloseModal}
        >
            <div
                className="bg-white dark:text-white dark:bg-gray-800 p-6 rounded-lg w-4/5 max-w-lg"
                onClick={(e) => e.stopPropagation()}
            >
                   <div className="flex items-start justify-between p-2 border-b rounded-t bg-white dark:bg-gray-800 dark:border-gray-700">
                  <h3 className="text-xl font-bold dark:text-white">
                    Detalii programare
                  </h3>
                  <button type="button" onClick={handleCloseModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                  </button>
                </div>
                {selectedAppointment.type === 'blocat' ? (
                    <div className="flex flex-col p-2">
                    <div className="text-xl font-bold mb-4">Blocat</div>
                        <button
                            className="px-4 py-2 bg-red-700 text-white rounded-lg hover:bg-red-600 mr-4"
                            onClick={() => handleDeleteAppointment(selectedAppointment)}
                        >
                            Sterge programarea
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col p-2">
                        <h2 className="text-xl font-bold mb-2">
                            {selectedAppointment.date}
                        </h2>
                        <p className="mb-2">Ora: {selectedAppointment.hour}</p>
                        <p className="mb-2">
                            Client: {selectedAppointment.firstName} {selectedAppointment.lastName}{" "}
                        </p>
                        <p className="mb-2">Masina: {selectedAppointment.position}</p>
                        <p className="mb-2">Nr. tel: {selectedAppointment.phone}</p>
                        <button
                            className="px-4 py-2 bg-red-700 text-white rounded-lg hover:bg-red-600 mr-4"
                            onClick={() => handleDeleteAppointment(selectedAppointment)}
                        >
                            Sterge programarea
                        </button>
                    </div>
                )}
            </div>
        </div>
    )}
    </div>
  );
};

export default Calendar2;
