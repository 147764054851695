import React, { useState, useEffect } from "react";
import Logo from "./logo";
import { Link, useLocation } from 'react-router-dom';
import ThemeSwitcher from "./ThemeSwitcher";
const BasicNav = ({ showButton }) => {
  const location = useLocation();
  const [darkMode, setDarkMode] = useState(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    return isDarkMode;
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', String(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <nav className="bg-white relative w-full border-gray-200 px-4 py-2.5 dark:bg-gray-800 md:mb-6 ">
      <div className="flex flex-wrap justify-between items-center mx-auto ">
        <div href="https://servicegest.ro" className="flex items-center">
          <Logo width="60px" height="50px" darkMode={darkMode} />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Servicegest <span className="self-center italic text-xs whitespace-nowrap dark:text-white">beta</span></span>
        </div>
        <div className="flex items-center lg:order-2 gap-2 ">
 
          <ThemeSwitcher darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          {location.pathname === '/' && (
          <Link 
            to="/login"
            className=" text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
          >
            Login Service
          </Link>
        )}
        </div>
      </div>
    </nav>
  );
};

export default BasicNav;
