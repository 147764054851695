import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { setDoc } from "firebase/firestore";
import carList from './car-list.json';


function UserCarsEdit( { isOpen, onClose, clientId, carId}) {
    const [carDetails, setCarDetails] = useState({ position: '', description: '' });

    useEffect(() => {
      const fetchCarDetails = async () => {
        const user = auth.currentUser;
        if (user) {
          const carDocRef = doc(firestore, 'customers', user.uid, 'clients', clientId, 'car', carId);
          const carDocSnap = await getDoc(carDocRef);
          if (carDocSnap.exists()) {
            const data = carDocSnap.data();
            const [marca, model] = data.description.split(' ');
            setCarDetails({ ...data, model, marca });
          }
        }
      };
    
      fetchCarDetails();
    }, [carId, clientId]);



      const handleSubmit = async (e) => {
        e.preventDefault();
        const user = auth.currentUser;
        
        if (!user) {
          alert('User not logged in');
          return;
        }
      
        const carDocRef = doc(firestore, 'customers', user.uid, 'clients', clientId, 'car', carId);
      
        const updatedCar = {};
      
        if (carDetails.position !== undefined) {
          updatedCar.position = carDetails.position;
        }
      
        if (carDetails.description !== undefined) {
          updatedCar.description = carDetails.description;
        }
      
        try {
          await setDoc(carDocRef, updatedCar, { merge: true });
          console.log("Document updated successfully");
          alert('Masina a fost editata cu succes');
          window.location.reload();
        } catch (error) {
          console.error("Error updating document: ", error);
        }
      };
      const [inputValue, setInputValue] = useState('');
      const [selectedBrand, setSelectedBrand] = useState(null);

const handleChange = (e) => {
  setCarDetails(prevDetails => {
    const newDetails = { ...prevDetails, [e.target.name]: e.target.value };
    if (e.target.name === 'marca') {
      const brand = filteredCarList.find(car => car.brand === e.target.value);
      setSelectedBrand(brand);
      newDetails.model = ''; // clear the model input
    }
    if (e.target.name === 'marca' || e.target.name === 'model') {
      newDetails.description = `${newDetails.marca} ${newDetails.model}`;
    }
    return newDetails;
  });
};
    
      const filteredCarList = carList.filter(car => car.brand.includes(inputValue));
    return (
        <div className={`fixed left-0 inline-flex right-0 z-50 items-center bg-slate-800/50 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0 h-modal sm:h-full ${isOpen ? '' : 'hidden'}`} id="editClientModal" aria-hidden="true"> 
     <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          {/* Modal header */}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Editare masina
            </h3>
            <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                     <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6">
          <form id="editCarForm" onSubmit={handleSubmit}>
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="position" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nr. Inmatriculare</label>
          <input type="text" name="position" id="position" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"  value={carDetails.position} onChange={handleChange} placeholder={carDetails.position} /* rest of the props */ />
        </div>
        <div className="col-span-6 sm:col-span-3 ">
          <label htmlFor="marca" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Marcă <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
        <input 
            id="marca" 
            name="marca"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
            placeholder="Renault"
            value={carDetails.marca}
            onChange={handleChange}
            list="car-brands"
            required
        />
        <datalist id="car-brands">
            {filteredCarList.map((car, index) => (
                <option key={index} value={car.brand} />
            ))}
        </datalist>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label htmlFor="model" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Model <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
          <input 
            id="model" 
            name="model"
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
            placeholder="Megane"
            list={`car-models-${carDetails.marca}`} // Append the brand to the list attribute
            value={carDetails.model}
            onChange={handleChange}
            required
          />
          <datalist id={`car-models-${carDetails.marca}`}>
            {selectedBrand && selectedBrand.models.map((model, index) => (
              <option key={index} value={model} />
            ))}
          </datalist>
        </div>
      </div>
              <div className="items-center mt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                <button className="mt-4 text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Salveaza</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    );
}

export default UserCarsEdit;