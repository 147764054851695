import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { doc, collection, query, getDocs, deleteDoc } from 'firebase/firestore';
import EditEmployee from './editEmployee';

const EmployeeList = () => {
  const [employees, setEmployees] = useState([]);
  const location = useLocation();
  const [editEmployeeId, setEditEmployeeId] = useState(null);

  const handleEditClick = (id) => {
    setEditEmployeeId(id);
    console.log('Edit button clicked');
    console.log(id);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const { uid } = auth.currentUser;

      const q = query(collection(doc(collection(firestore, 'customers'), uid), 'employees'));
      const querySnapshot = await getDocs(q);
      const employees = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      console.log('Fetching employees from Firestore');
      setEmployees(employees);
    };

    fetchEmployees();
  }, []);
    const handleDelete = async (employeeId, employeeName, employeeLast) => {
      const confirmation = window.confirm(`Ești sigur că vrei să ștergi mecanicul ${employeeName} ${employeeLast}? În cazul în care ai programări pentru acest mecanic, acestea vor fi șterse.`);
      if (!confirmation) {
        return;
      }

      const { uid } = auth.currentUser;

      if (!uid || !employeeId) {
        console.error('UID or employee ID is empty');
        return;
      }

      const docRef = doc(firestore, 'customers', uid, 'employees', employeeId);
      await deleteDoc(docRef);

      const newEmployees = employees.filter(employee => employee.id !== employeeId);
      setEmployees(newEmployees);
    };
  
    return (
      <div className="w-full">
       {employees.map(employee => (
  <div key={employee.id} className="bg-gray-200 p-2 w-full rounded-lg my-2 dark:bg-slate-900 inline-grid">
    <h2>{employee.employeeName} {employee.employeeLast}</h2>
    <p>{employee.employeeTag}</p>
    
    {location.pathname !== '/dashboard' && (
      <div className="flex flex-row justify-between gap-4">
        <button onClick={() => handleDelete(employee.id, employee.employeeName, employee.employeeLast)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-lg">
          Delete
        </button>
        <button onClick={() => handleEditClick(employee.id)} className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-lg">Edit</button>       
      </div>
    )}
    {editEmployeeId === employee.id && <EditEmployee employee={employee} closeEdit={() => setEditEmployeeId(null)} />} 
    
   </div>
))}
      </div>
    );
  };
  
  export default EmployeeList;