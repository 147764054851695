import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../firebase';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';


const CalendarUserInfo = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const month = currentMonth.getMonth();
    const year = currentMonth.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const totalAppointmentsPerDay = 8; 
    const colorClasses = {
      green: 'bg-green-500',
      yellow: 'bg-yellow-500',
      orange: 'bg-yellow-600',
      red: 'bg-red-500',
    };
    const nextMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
      };
    const prevMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
      };
    const [appointments, setAppointments] = useState([]);
    const days = [];
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i);
    }
    
    const dayAppointments = appointments.filter(appointment => new Date(appointment.date).getDate() === selectedDate);

    const [firstDayOfMonth, setFirstDayOfMonth] = useState((new Date(year, month, 1).getDay() + 6) % 7);

    useEffect(() => {
      setFirstDayOfMonth((new Date(year, month, 1).getDay() + 6) % 7);
    }, [currentMonth, month, year]);
  const prevMonthDays = new Date(year, month, 0).getDate();
  const startGap = firstDayOfMonth;
  const currentMonthDays = new Date(year, month + 1, 0).getDate();
  const endGap = (7 - ((currentMonthDays + startGap) % 7)) % 7;
  function createDateFromString(dateString) {
    const [year, month, day] = dateString.split('.');
    return new Date(year, month - 1, day);
  }

    useEffect(() => {
const fetchAppointments = async () => {
  const user = auth.currentUser;
  console.log(user);
  if (user) {
    const appointmentsRef = collection(doc(collection(firestore, 'customers'), user.uid), 'appointments');
    console.log(appointmentsRef);
    function formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}.${month}.${day}`;
    }
    
    const startOfMonth = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1));
    const endOfMonth = formatDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0));
    console.log(startOfMonth, endOfMonth);
    
    try {
      const snapshot = await getDocs(query(appointmentsRef, where('date', '>=', startOfMonth), where('date', '<=', endOfMonth)));
      console.log("Documents: ", snapshot.docs.map(doc => doc.data()));
      
      setAppointments(snapshot.docs.map(doc => {
        const data = doc.data();
        data.date = createDateFromString(data.date);
        return data;
      }));
    } catch (error) {
      console.error("Error fetching appointments: ", error);
      setAppointments([]);
    }
  } else {
    setAppointments([]);
  }
};

fetchAppointments();
    }, [currentMonth]);

   return (
                  <div className="w-128 bg-gray-200 dark:bg-slate-900 p-8 rounded-lg">
                    <div className="flex justify-between items-center mb-4">
                  <button onClick={prevMonth}>&lt;</button>
                  <h2>{(currentMonth.toLocaleString('ro-RO', { month: 'long' }).charAt(0).toUpperCase() + currentMonth.toLocaleString('ro-RO', { month: 'long' }).slice(1))} {currentMonth.getFullYear()}</h2>        <button onClick={nextMonth}>&gt;</button>
                </div>
                <div className="h-full grid grid-cols-7 gap-2 xs:-m-5">
              {['Lun', 'Mar', 'Mie', 'Joi', 'Vin', 'Sâm', 'Dum'].map((dayOfWeek, index) => (
              <div key={index} className="p-2 text-center self-center justify-self-center">
                  {dayOfWeek}
              </div>
          ))}

            {Array(startGap).fill().map((_, index) => (
              <div key={`prev-${index}`} className="w-10 h-10 p-2 text-center text-gray-400 self-center justify-self-center">
                {prevMonthDays - startGap + index + 1}
               
              </div>
            ))}

        {days.map(day => {
          const dayAppointments = appointments.filter(appointment => new Date(appointment.date).getDate() === day);
          const freeTimePercentage = (dayAppointments.length / totalAppointmentsPerDay) * 100;

          let color;
          if (freeTimePercentage <= 25) {
              color = 'green';
          } else if (freeTimePercentage <= 50) {
              color = 'yellow';
          } else if (freeTimePercentage <= 75) {
              color = 'orange';
          } else {
              color = 'red';
          }

          const currentDate = new Date(year, month, day);
          currentDate.setHours(0, 0, 0, 0);
          
          const currentToday = new Date();
          currentToday.setHours(0, 0, 0, 0);
        
          return (
            <div 
            key={day} 
            className={`w-10 h-10 p-2 text-center cursor-pointer rounded-lg self-center justify-self-center 
              ${day === selectedDate ? 'bg-blue-500 text-white' : ''} 
              ${currentDate.getTime() === currentToday.getTime() ? 'text-red-600 font-bold' : ''} 
              ${currentDate.getTime() < currentToday.getTime() ? 'opacity-50' : ''} 
              ${colorClasses[color]}`}
            onClick={() => {
              setSelectedDate(day);
              setIsModalOpen(true);
            }}
          >
            {day}
          </div>
          );
        })}

        {Array(endGap).fill().map((_, index) => (
          <div 
            key={`next-${index}`} 
            className="w-10 h-10 p-2 text-center text-gray-400 self-center justify-self-center"
          >
            {index + 1}
          </div>
        ))}
     
   

          </div>
       
                  {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-hidden">
            <div className="modal-content bg-white dark:bg-slate-800 rounded-lg p-5 overflow-auto h-1/2 w-11/12 md:w-1/2 lg:w-1/3">
              <div className="sticky dark:bg-slate-800 mb-4 p-2 -top-5">
              <span className="block font-bold close text-right cursor-pointer" onClick={() => setIsModalOpen(false)}>&times;</span>
              <h2 className="text-center mb-4">Programari pentru: {selectedDate} {(currentMonth.toLocaleString('ro-RO', { month: 'long' }).charAt(0).toUpperCase() + currentMonth.toLocaleString('ro-RO', { month: 'long' }).slice(1))}</h2>
            </div>
            {dayAppointments
            .sort((a, b) => a.hour.localeCompare(b.hour))
            .map(appointment => (
              <div key={appointment.id} className="mb-3 bg-gray-200 dark:bg-slate-900 p-2 rounded-lg">
                <h3 className="font-bold">Data:{appointment.date.toLocaleDateString()}</h3>            
                <p>Ora: {appointment.hour}</p>
                <p>Nume: {appointment.firstName} {appointment.lastName}</p>
                <p>Mecanic: {appointment.employeeTag}</p>
                <p>Status: {appointment.status}</p>
              </div>
          ))}
            </div>
    
          </div>
        )}
             
        </div>
        
      );
    };
    
    export default CalendarUserInfo;