import React, { useState, useEffect } from "react";
import { auth, firestore } from "../firebase";
import { setDoc, doc, getDoc } from "firebase/firestore";



const UserHours = () => {

  const [startHour, setStartHour] = useState('00:00');
  const [endHour, setEndHour] = useState('24:00');

  const handleSave = async () => {
    const user = auth.currentUser;
    if (user && user.uid) {
      const docData = {
        startHour,
        endHour
      };
      const docRef = doc(firestore, 'customers', user.uid);
      await setDoc(docRef, docData, { merge: true });
      alert('Orarul a fost salvat cu succes!');
      window.location.reload();
    } else {
      console.error('No user signed in');
    }
  };
  const hours = [...Array(24).keys()];
  const minutes = ['00'];

  useEffect(() => {
    const fetchHours = async () => {
      const user = auth.currentUser;
      if (user && user.uid) {
        const docRef = doc(firestore, 'customers', user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setStartHour(docSnap.data().startHour);
          setEndHour(docSnap.data().endHour);
        } else {
          console.log('No such document!');
        }
      } else {
        console.error('No user signed in');
      }
    };

    fetchHours();
  }, []);

  return (
    <div className="flex flex-col items-start justify-between mb-4 rounded-lg p-4 dark:bg-slate-900" id="userDetails">
    <h2 className="text-2xl font-bold mb-4">Setări orar de lucru</h2>
    <h2 className="text-2xl text-red-600 font-bold mb-4">Vă rugăm să selectați orele de lucru și să adăugați un mecanic. Altfel, meniul Calendar nu va funcționa!</h2>
    <div className="mb-4 w-full">
      <label htmlFor="startHour" className="block text-sm font-medium text-gray-700 dark:text-white">De la:</label>
      <div className="mt-1 gap-2 rounded-lg shadow-sm flex">    
    <select value={startHour ? startHour.split(':')[0] : ''} onChange={(e) => setStartHour(e.target.value + ':' + (startHour ? startHour.split(':')[1] : '00'))} className="block w-1/2 dark:text-black rounded-lg">
      {hours.map(hour => <option key={hour} value={hour}>{hour}</option>)}
    </select>
    <select value={startHour ? startHour.split(':')[1] : ''} onChange={(e) => setStartHour((startHour ? startHour.split(':')[0] : '00') + ':' + e.target.value)} className="block w-1/2 dark:text-black rounded-lg">
      {minutes.map(minute => <option key={minute} value={minute}>{minute}</option>)}
    </select>
  </div>
    </div>
    <div className="mb-4 w-full">
      <label htmlFor="endHour" className="block text-sm font-medium text-gray-700 dark:text-white">Pana la:</label>
      <div className="mt-1 gap-2 rounded-lg shadow-sm flex ">
    <select value={endHour ? endHour.split(':')[0] : ''} onChange={(e) => setEndHour(e.target.value + ':' + (endHour ? endHour.split(':')[1] : '00'))} className=" rounded-lg block w-1/2 dark:text-black">
      {hours.map(hour => <option key={hour} value={hour}>{hour}</option>)}
    </select>
    <select value={endHour ? endHour.split(':')[1] : ''} onChange={(e) => setEndHour((endHour ? endHour.split(':')[0] : '00') + ':' + e.target.value)} className=" rounded-lg block w-1/2 dark:text-black">
      {minutes.map(minute => <option key={minute} value={minute}>{minute}</option>)}
    </select>
  </div>
    </div>
    <button onClick={handleSave} className="mt-2 bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded-lg">Salvare</button>
  </div>
  );

}

export default UserHours;

