import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, doc, getDoc, deleteDoc} from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import CarInterventionModal from './carInterventionModal';
import AddCarItp from './addCarItp';
import UserCarsEdit from './UserCarsEdit';
import { jsPDF } from "jspdf";
import 'jspdf-autotable';

function UserCars({ clientId, showCars }) {
  const [carId, setCarId] = useState(null);
  const [cars, setCars] = useState([]);
  const [client, setClient] = useState([]);
  const [carInterventionModalOpen, setCarInterventionModalOpen] = useState(false);
  const [interventionsCount, setInterventionsCount] = useState([]);
  const [isAddCarItpModalOpen, setIsAddCarItpModalOpen] = useState(false);
  const [editCarId, setEditCarId] = useState(null);
  const [isEditCarModalOpen, setIsEditCarModalOpen] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const refresh = () => {
    setRefreshKey(oldKey => oldKey + 1);
  };
  const openCarInterventionModal = (event) => {
    setCarId(event.currentTarget.getAttribute('data-client-id'));
    setCarInterventionModalOpen(true);
  };

  const AddCarItpModalOpen = (event) => {
    setCarId(event.currentTarget.getAttribute('data-client-id'));
    setIsAddCarItpModalOpen(true);
  };

const [itpDates, setItpDates] = useState([]);
useEffect(() => {
  const fetchItpDates = async () => {
      const userId = auth.currentUser.uid;
      const newItpDates = [];

      for (let car of cars) {
          const carRef = doc(firestore, 'customers', userId, 'clients', clientId, 'car', car.id);
          const carSnapshot = await getDoc(carRef);

          if (carSnapshot.exists()) {
              const carData = carSnapshot.data();
              newItpDates.push(carData.itp);
          } else {
              console.log('No such document!');
              newItpDates.push(null);
          }
      }

      setItpDates(newItpDates);
  };

  fetchItpDates();
}, [cars, refreshKey, clientId]);

  useEffect(() => {
    const fetchInterventionsCount = async () => {
        const userId = auth.currentUser.uid;
        const clientRef = doc(firestore, 'customers', userId, 'clients', clientId);
        const interventionsCount = [];

        for (let car of cars) {
            const interventionsCollection = collection(clientRef, 'car', car.id, 'intervention');
            const querySnapshot = await getDocs(interventionsCollection);
            interventionsCount.push(querySnapshot.size);
        }

        setInterventionsCount(interventionsCount);
    };

    fetchInterventionsCount();
}, [cars, clientId]);
  const fetchClientCars = useCallback(async () => {
      const user = auth.currentUser;
      if (user) {
          const carsRef = collection(firestore, 'customers', user.uid, 'clients', clientId, 'car');
          const carsSnap = await getDocs(carsRef);
          const carsData = carsSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('carsData:', carsData);
          setCars(carsData);
      }
  }, [clientId]);

  const fetchClient = useCallback(async () => {
      const user = auth.currentUser;
      if (user) {
          const clientRef = collection(firestore, 'customers', user.uid, 'clients');
          const clientSnap = await getDocs(clientRef);
          const clientData = clientSnap.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log('clientData:', clientData);
          
          const specificClient = clientData.find(c => c.id === clientId);
          setClient(specificClient);
      }
  }, [clientId]);

  useEffect(() => {
      fetchClient();
  }, [fetchClient]);

  useEffect(() => {
      fetchClientCars();
  }, [fetchClientCars]);

  async function exportInterventions(clientId, carId) {
    const userId = auth.currentUser.uid;

    const interventionsRef = collection(
      firestore,
      'customers',
      userId,
      'clients',
      clientId,
      'car',
      carId,
      'intervention'
    );

  const carRef = doc(firestore, 'customers', userId, 'clients', clientId, 'car', carId);
  const carSnapshot = await getDoc(carRef);
  const car = carSnapshot.data();
  const interventionsSnapshot = await getDocs(interventionsRef);

  const interventions = interventionsSnapshot.docs.map(doc => doc.data());

  interventions.sort((a, b) => new Date(a.date) - new Date(b.date));
  const pdf = new jsPDF();

  const data = interventions.map(intervention => [
    intervention.date,
    intervention.denumire,
    intervention.um,
    intervention.cantitate,
    intervention.pretUnitar,
    intervention.valoare,
    intervention.valtotal
  ]);

  const totalPrice = interventions.reduce((total, intervention) => total + Number(intervention.valtotal), 0);

  pdf.autoTable({
    startY: 25,
    head: [["Data", "Denumire", "Um", "Cantitate", "Pret Unitar", "Valoare", "Valoare Totala"]],
    body: data,
    foot: [['Total', interventions.length, `${totalPrice.toFixed(2)} RON`]],
    styles: { fontSize: 10 },
    footStyles: { fontSize: 10, fontStyle: 'bold' },
    didDrawPage: (data) => {
      const doc = data.doc;
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text(`Client: ${client.firstName} ${client.lastName}`, data.settings.margin.left, 10);
      doc.text(`Model masina: ${car.description}`, data.settings.margin.left, 15);
      doc.text(`Nr. inmatriculare: ${car.position}`, data.settings.margin.left, 20);
    },
  });

  const fileName = `interventii-${client.firstName}-${client.lastName}-${car.position}.pdf`;
pdf.save(fileName);
}
const deleteCar = async (clientId, carId) => {
  const carDoc = doc(firestore, 'customers', auth.currentUser.uid, 'clients', clientId, 'car', carId);
  try {
    await deleteDoc(carDoc);
    console.log(`Car with id ${carId} has been deleted.`);
    // Update the cars state to trigger a re-render
    setCars(prevCars => prevCars.filter(car => car.id !== carId));
  } catch (error) {
    console.error('Error deleting car: ', error);
  }
};

return (
  <>
    {showCars === clientId && (
        <table className="min-w-full  divide-gray-200  dark:divide-gray-600">
         <thead className="bg-gray-100 dark:bg-gray-700">
                <tr>
                  <th scope="col" className="p-4">
                  </th>
            
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Model mașină
                   
                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Nr. înmatriculare
                 
                  </th>
                  <th scope="col" className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                  Intervenții
                  </th>
                  <th scope="col" className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Valabitate ITP
                  
                  </th>
                  <th scope="col" className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Acțiuni
                  </th>
                </tr>
              </thead>
                      <tbody className=" bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700" id="searchResults">
  {cars.length > 0 ? (
    cars.map((car, index) => (
      <React.Fragment key={index}>
        <tr key={index}>
          <td className="w-4 p-4">
            <div className="flex items-center p-2 opacity-0">
              <input id={`checkbox-${car.id}`} aria-describedby={`checkbox-${car.id}`} type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:focus:ring-primary-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" data-car-id={car.id} />
              <label htmlFor={`checkbox-${car.id}`} className="sr-only">checkbox</label>
            </div>
          </td>
       
          <td className="max-w-sm p-4 overflow-hidden text-base font-normal text-gray-500 truncate xl:max-w-xs dark:text-gray-400">{car.description}</td>
        <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">{car.position}</td>
        <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white"> {interventionsCount[index]}</td>
        <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">
  {itpDates[index] && itpDates[index].endDate ? `${itpDates[index].endDate}` : 'Nu avem date...'}
</td>
        <td className="p-4 space-x-2 whitespace-nowrap w-0">
              
        <button 
            type="button"  
            className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900"
            data-client-id={car.id} 
            onClick={openCarInterventionModal}
           >
          <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path fillRule="evenodd" stroke="currentColor" strokeWidth="2" d="M12 22c-.552 0-1-.448-1-1v-8H3c-.552 0-1-.448-1-1s.448-1 1-1h8V3c0-.552.448-1 1-1s1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1z" clipRule="evenodd" />
          </svg>
          Intervenție
      </button>

      {carInterventionModalOpen && (
          <CarInterventionModal 
            isOpen={carInterventionModalOpen}
            onClose={() => {
              setCarInterventionModalOpen(false);
              setCarId(null);
            }}
            clientId={client.id}
            carId={carId}
          />
        )}

              <button
            type="button"
            className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900"
            data-client-id={car.id}
            onClick={AddCarItpModalOpen}
        >
            <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fillRule="evenodd" stroke="currentColor" strokeWidth="2" d="M12 22c-.552 0-1-.448-1-1v-8H3c-.552 0-1-.448-1-1s.448-1 1-1h8V3c0-.552.448-1 1-1s1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1z" clipRule="evenodd" />
            </svg>
            ITP
        </button>
        {AddCarItpModalOpen && (
          <AddCarItp 
          refreshParent={refresh}
            isOpen={isAddCarItpModalOpen} 
            onClose={() => {
              setIsAddCarItpModalOpen(false);

            }} 
            clientId={client.id} 
            carId={carId} 
          />
        )}      
    
    <button 
          type="button"  
          className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900"
          onClick={() => {
            setEditCarId(car.id); // Set the editCarId state to the car.id
            setIsEditCarModalOpen(true); // Open the modal
          }}
          data-client-id={car.id}

        >
          
          <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-8 8a1 1 0 01-.683.267H6a1 1 0 01-1-1V9.97a1 1 0 01.267-.684l8-8zM9 11h2v2H9v-2zm-1-8h2v2H8V3z" clipRule="evenodd"></path>
                </svg>
            Editare Masina
        </button>
        {isEditCarModalOpen && (
  <UserCarsEdit
    isOpen={isEditCarModalOpen}
    onClose={() => {
      setIsEditCarModalOpen(false);
      setEditCarId(null);
    }}
    clientId={client.id}
    carId={editCarId}
  />
)}



       <button
          type="button"
          className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900"
          data-client-id={car.id}
          onClick={() => exportInterventions(client.id, car.id)}
          clientId={client.id}
          carId={carId}
        >
        <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z" clipRule="evenodd" />
                      </svg>
          Export Istoric
        </button>
        <button
          type="button"
          className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900"
          data-client-id={car.id}
          onClick={() => deleteCar(client.id, car.id)}
          clientId={client.id}
          carId={carId}
        >
          <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
          Șterge
        </button>
         </td>
        </tr>
        </React.Fragment>
    ))
  ) : (
    <tr>
      <div className="flex items-center justify-center w-full  dark:text-white h-24">
        <h1>No cars available. Add a car with the "Add Car" button!</h1>
      </div>
    </tr>
  )}
</tbody>
        </table>
     )}
     </>
   );
 }
export default UserCars;