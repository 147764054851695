import React, { useState, useEffect } from 'react';
import { auth, db} from "../firebase";
import { doc, updateDoc, getDoc} from "firebase/firestore";

const EditEmployee = ({ employeeId, closeEdit, employee: employeeProp }) => {
  const [employee, setEmployee] = useState(employeeProp);  console.log('EditEmployee rendered');
  const [isOpen, setIsOpen] = useState(true);
  const [employeeFirst, setEmployeeFirst] = useState('');
  const [employeeLast, setEmployeeLast] = useState('');
  const [specializare, setSpecializare] = useState({
    schimburi: false,
    itp: false,
    mecanica: false,
    electrica: false,
    tinichigerie: false,
    vulcanizare: false,
    climatizare: false,
    detailing: false,
    diagnosticare: false,
    esapamente: false,
  });

  useEffect(() => {
    if (employee) {
      setEmployeeFirst(employee.employeeName);
      setEmployeeLast(employee.employeeLast);
    }
  }, [employee]);

  if (!isOpen) {
    return null;
  }
  const handleInputChange = (event) => {
    const { name, checked } = event.target;
    setEmployee(prevState => ({
      ...prevState,
      specializari: {
        ...prevState.specializari,
        [name]: checked
      }
    }));
  };

  const updateSpecializari = async (e) => {
    e.preventDefault();

    try {
      const user = auth.currentUser;
      const docRef = doc(db, 'customers', user.uid, 'employees', employee.id);  

      let specializariToUpdate = employee.specializari;

      if (Object.keys(specializariToUpdate).length === 0) {
        // Initialize specializari with existing fields in Firestore document
        const docSnapshot = await getDoc(docRef);
        specializariToUpdate = docSnapshot.data().specializari;
      }

      // Delete fields that are set to false
      for (let key in specializariToUpdate) {
        if (specializariToUpdate[key] === false) {
          delete specializariToUpdate[key];
        }
      }

      await updateDoc(docRef, {
        specializari: specializariToUpdate
      });

      console.log('Document successfully updated!');
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };
    return (
        <div className="modal fixed inline-flex bg-gray-800/80 dark:bg-slate-800/80 left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full" id="add-employee-modal" aria-hidden="true">
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
               Editare mecanic
              </h3>
              <button 
              type="button" 
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" 
              data-modal-toggle="add-user-modal"
              onClick={closeEdit}
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
            </div>
            <div className="p-6 space-y-6">
            <form id="addEmployeeForm" >
                <div className="grid grid-cols-6 gap-6 pb-4">
                  <div className="col-span-6 sm:col-span-3">
                    <span htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Nume</span>
                    <input type="text" name="first-name" value={employee.employeeLast} id="employeeName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Andrei" required />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <span htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Prenume</span>
                    <input type="text" name="last-name" value={employee.employeeName} id="employeeLast" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Ionescu" required />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Specializare</span>
                  <div className="flex space-x-4 justify-between mb-2">
                  <div className="space-y-2">
                  <label htmlFor="schimburi" className="flex items-center">
                    <input type="checkbox" id="schimburi"
                    onChange={handleInputChange}
                     checked={employee.specializari.schimburi}
 name="schimburi" value="schimburi" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Schimburi</span>
                  </label>
                  <label htmlFor="itp" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}

                    checked={employee.specializari.itp}
 id="itp" name="itp" value="itp" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">ITP</span>
                  </label>
                  <label htmlFor="mecanica" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}
                                          checked={employee.specializari.mecanica}
 id="mecanica" name="mecanica" value="mecanica" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Mecanica</span>
                  </label>
                  <label htmlFor="electrica" className="flex items-center">
                    <input type="checkbox"  
                                          onChange={handleInputChange}
                                          checked={employee.specializari.electrica}
 id="electrica" name="electrica" value="electrica" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Electrica</span>
                  </label>
                  <label htmlFor="tinichigerie" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}
                                          checked={employee.specializari.tinichigerie}
 id="tinichigerie" name="tinichigerie" value="tinichigerie" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Tinichigerie</span>
                  </label>
                
                  </div>
                  <div className="space-y-2">
                  <label htmlFor="vulcanizare" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}
                                          checked={employee.specializari.vulcanizare}
id="vulcanizare" name="vulcanizare" value="vulcanizare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Vulcanizare</span>
                  </label>
                  <label htmlFor="climatizare" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}
                                          checked={employee.specializari.climatizare}
 id="climatizare" name="climatizare" value="climatizare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Climatizare</span>
                  </label>
                  <label htmlFor="detailing" className="flex items-center">
                    <input type="checkbox"   
                                          onChange={handleInputChange}
                                          checked={employee.specializari.detailing}
id="detailing" name="detailing" value="detailing" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Detailing</span>
                  </label>
                  <label htmlFor="diagnosticare" className="flex items-center">
                    <input type="checkbox"    
                                          onChange={handleInputChange}
                                          checked={employee.specializari.diagnosticare}
id="diagnosticare" name="diagnosticare" value="diagnosticare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Diagnosticare</span>
                  </label>
                  <label htmlFor="esapamente" className="flex items-center">
                    <input type="checkbox"    
                      onChange={handleInputChange}
                    checked={employee.specializari.esapamente}
 id="esapamente" name="esapamente" value="esapamente" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Esapamente</span>
                  </label>
                </div>
                </div>
              </div>
                <div className="items-center border-t border-gray-200 rounded-b dark:border-gray-700">
                <button 
  className=" mt-6  text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" 
  type="submit"
  onClick={updateSpecializari}
>
  Salvează
</button>
                              </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
};

export default EditEmployee;