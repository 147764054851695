import React, { useState } from "react";
import BasicNav from "../components/basicNav";
import { auth, firestore } from '../firebase';
import { collection, doc, setDoc, onSnapshot } from 'firebase/firestore';
import Loading from "../components/loading";



function PricingPage() {
const [isLoading, setIsLoading] = useState(false);
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const user = auth.currentUser;
        if (user) {
            const checkoutSessionRef = doc(collection(firestore, 'customers', user.uid, 'checkout_sessions'));


            await setDoc(checkoutSessionRef, {
              line_items: [
                {
                  price: 'price_1OajqCA6IzncjeCxHezre8Dd',
                  quantity: 1,
                },
              ],
              payment_method_types: ['card'],
              mode: 'subscription',
              success_url: `${window.location.origin}/dashboard/settings`,
              cancel_url: window.location.origin,
            });

  onSnapshot(checkoutSessionRef, (snap) => {
    const { sessionId } = snap.data();
    if (sessionId) {
      const stripe = window.Stripe('pk_test_51OYmBEA6IzncjeCxroNDzgck1BooVijAy2rjZnG51SiV833tjKgOLcB9RhsPpFC6SqZ2nXtJLP19KWy6SF12W4MX004tCBloz3');
      stripe.redirectToCheckout({ sessionId })
        .then(() => {
          setIsLoading(false);
        });
    }
  });
} else {
  console.log('No user is signed in');
}
      };
    return (
      <>
      {isLoading ? (
        <Loading /> 
      ) : (
        <section className="bg-gray-50 dark:bg-gray-900 h-screen">
          <BasicNav />
        <main className="dark:bg-gray-900">
    <div className="container pt-10 mx-auto md:pt-10 lg:px-0 dark:bg-gray-900">
      <h1 className="mb-3 text-3xl font-bold text-gray-900 sm:text-4xl sm:leading-none sm:tracking-tight dark:text-white">Our pricing plan made simple</h1>
      <p className="mb-6 text-lg font-normal text-gray-500 sm:text-xl dark:text-gray-400">All types of businesses need access to development resources, so we give you the option to decide how much you need to use.</p>
      <section className="grid grid-cols-1 space-y-12 md:space-y-0 md:grid-cols-1 lg:grid-cols-1 md:gap-x-6 md:gap-6 pt-9">
        <div className="flex flex-col max-w-lg p-6 mx-auto text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 xl:p-8 dark:bg-gray-800 dark:text-white">
          <h3 className="mb-4 text-2xl font-semibold">Pachet Standard</h3>
          <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">Pachet Standard</p>
          <div className="flex items-baseline my-8">
            <span className="mr-2 text-4xl font-extrabold">250 RON</span>
            <span className="text-gray-500 dark:text-gray-400">/month</span>
          </div>
          <ul className="mb-8 space-y-4 text-left">
            <li className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
              <span>Pachet Standard</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
              <span>Pachet Standard</span>
            </li>
            <li className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
              <span>Team size: <span className="font-semibold">Pachet Standard</span></span>
            </li>
            <li className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
              <span>Premium support: <span className="font-semibold">1 luna gratis</span></span>
            </li>
            <li className="flex items-center space-x-3">
              <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" /></svg>
              <span>Free updates: <span className="font-semibold">10% reducere</span></span>
            </li>
          </ul>
          <form id="checkout-form" onSubmit={handleFormSubmit} className="self-center ">
            <input type="hidden" name="lookup_key" defaultValue={2} />
            <button id="checkout-and-portal-button" className=" self-center text-white bg-purple-700 hover:bg-purple-900 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-800 dark:bg-purple-700 dark:border-gray-700 dark:text-white dark:hover:bg-gray-200 me-2 mb-2" type="submit">
            Checkout</button>
          </form>
        </div>
      </section>
    </div>
  </main>
        </section>
         )}
       </>
    );
    }

export default PricingPage;