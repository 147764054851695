import React from "react";
import AppointmentsToday from "./appointmentsToday";
import AppointmentsTomorrow from "./appointmnetsTomorrow";
import EmployeeList from "./employeeList";
import CalendarUserInfo from "./calendarUserInfo";
import SmartMetrics from "./smartMetrics";

const DashboardComp = () => {


  const currentDate = new Date();
  const currentDay = currentDate.toLocaleDateString('ro-RO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  const currentMonth = currentDate.toLocaleString('ro-RO', { month: 'long' });

  const tomorrowDate = new Date(currentDate);
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const tomorrowDay = tomorrowDate.toLocaleDateString('ro-RO', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    return (
<div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
  <main>
    <div className="p-4">
      <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">
        <div className="p-4 grid grid-cols-2 gap-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
        <div>
        <h2 className="text-xl font-bold mb-4">Astăzi: {currentDay}</h2>
          <div className="flex flex-col items-start justify-between mb-4 overflow-y-auto" style={{height: '25rem'}} id="upcomingAppointments">
        <AppointmentsToday />
          </div>
          </div>
          <div>
          <h2 className="text-xl font-bold mb-4">Mâine: {tomorrowDay}</h2>
          <div className="flex flex-col items-start justify-between mb-4 overflow-y-auto" style={{height: '25rem'}} id="upcomingAppointments">
        <AppointmentsTomorrow /> 
          </div>
          </div>
        </div>
        <div className="p-4 bg-white border dark:text-white border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
        <h2 className="text-xl font-bold mb-4">În luna curentă: {currentMonth}</h2>
        <CalendarUserInfo />
        <div className="flex space-evenly flex-row">
        Disponibilitate: <br/>
        <div className=''>
        <h3> <span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-green-500 align-middle"></span>peste 75%</h3>
  <h3><span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-yellow-500 align-middle"></span>între 50%-75%</h3>
        </div>
 <div>
 <h3> <span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-orange-500 align-middle"></span>între 25%-50%</h3>
  <h3><span className="m-1 inline-block h-2.5 w-2.5 rounded-full bg-red-500 align-middle"></span>sub 25%</h3>
 </div>
 
</div>
        </div>
      </div>
      <div className="grid w-full overflow-y-auto grid-cols-1 gap-4 mt-4 xl:grid-cols-2 2xl:grid-cols-2">
        <div className="grid gap-2 xl:grid-cols-1 2xl:grid-cols-2 ">
          <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
            <div className="flex flex-col w-full items-start justify-between mb-4" id="employee">
              <div className="flex flex-col gap-2 flex-wrap w-full">
              <h2 className="text-xl font-bold mb-4">Mecanici</h2>
                <EmployeeList />
              </div>
            </div>
          </div>
        </div>
        <div className="p-4 bg-white border dark:text-white border-gray-200 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
          <div className="w-full">
            <h2 className="text-xl font-bold mb-4">Date statistice</h2>
            <div className="flex flex-col items-start justify-between mb-4 overflow-y-auto">
              <SmartMetrics />
            </div>
          </div>
        </div>
      </div>

    </div>
  </main>
</div>
    
        );
    }

export default DashboardComp;
