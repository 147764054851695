import React from 'react';
import { firestore, auth } from '../firebase';
import { doc, collection, updateDoc, addDoc, increment } from 'firebase/firestore';

function InterventionModal({ isOpen, onClose, clientId }) {
    console.log(`InterventionModal rendered with isOpen: ${isOpen}`);
  
    if (!isOpen) {
      return null;
    }

    const addIntervention = async (event) => {
        event.preventDefault();
      
        const interventionDetails = {
            date: event.target.elements.date.value,
            details: event.target.elements.intDetails.value,
            price: event.target.elements.intPrice.value,
          };
      
        const userId = auth.currentUser.uid;
        const clientRef = doc(firestore, 'customers', userId, 'clients', clientId);
      
        // Increment the intervention counter
        await updateDoc(clientRef, {
          interventionCount: increment(1)
        });
      
        // Add the intervention details
        const interventionsCollection = collection(clientRef, 'interventions');
        await addDoc(interventionsCollection, interventionDetails)
          .then((docRef) => {
            console.log(`Intervention added with ID: ${docRef.id}`);
            onClose();
          })
          .catch((error) => {
            console.error(`Error adding intervention: ${error}`);
          });
          
      };
  
    return (
<div className={`fixed left-0 inline-flex right-0 z-50 items-center bg-slate-800/90 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0 h-modal sm:h-full ${isOpen ? '' : 'hidden'}`} id="editClientModal" aria-hidden="true"> 
     <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          {/* Modal header */}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Add intervention
            </h3>
            <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                     <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6">
            <form id="AddInterventionForm" onSubmit={addIntervention}>
              <div className="grid grid-cols-6 gap-6 pb-4">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Date</label>
                  <input type="date" id="date" name="date" className="w-full p-2 border dark:border-gray-300 rounded" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Details</label>
                  <input type="text" name="intDetails" id="intDetails" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Schimb de ulei + distributie" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
                  <input type="number" name="intPrice" id="intPrice" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="420.69 RON" required />
                </div>
              </div>
              <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700">
                <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Add intervention</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    );
}

export default InterventionModal;