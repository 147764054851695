import { useState } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';

const auth = getAuth();

function UserInfo() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState(''); // Add this line

  const handleUpdate = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      alert('Noua parolă și parola confirmată nu sunt la fel.');
      return;
    }

    if (auth.currentUser) {
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        currentPassword
      );

      reauthenticateWithCredential(auth.currentUser, credential)
        .then(() => {
          updatePassword(auth.currentUser, newPassword)
            .then(() => {
              alert('Parola a fost actualizată cu succes!');
            })
            .catch((error) => {
              if (error.code === 'auth/wrong-password') {
                alert('Parola curentă este incorectă.');
              } else {
                alert('A apărut o eroare la actualizarea parolei.');
              }
              console.error('A apărut o eroare:', error);
            });
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            alert('Parola curentă este incorectă.');
          } else {
            alert('A apărut o eroare la reautentificare.');
          }
          console.error('A apărut o eroare:', error);
        });
    } else {
      alert('Niciun utilizator nu este autentificat în prezent.');
    }
  };
 
  return (
    <div className="flex flex-col items-start justify-between mb-4 dark:bg-slate-900 rounded-lg p-4" id="userDetails">
      <h2 className="text-2xl font-bold mb-4">Detalii parola</h2>
      <div className="mb-2 w-1/2">
        <span htmlFor="currentPasswordInput" className="font-bold">Parola curentă</span>
        <input id="currentPasswordInput" type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="ml-2 border rounded p-1 dark:text-zinc-800 text-gray-900 w-full" />
      </div>
      <div className="mb-2 w-1/2">
        <span htmlFor="newPasswordInput" className="font-bold">Parola nouă</span>
        <input id="newPasswordInput" type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="ml-2 border rounded p-1 dark:text-zinc-800 text-gray-900 w-full" />
      </div>
      <div className="mb-2 w-1/2">
        <span htmlFor="confirmNewPasswordInput" className="font-bold">Confirmă parola nouă</span>
        <input id="confirmNewPasswordInput" type="password" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="ml-2 border rounded p-1 dark:text-zinc-800 text-gray-900 w-full" />
      </div>
      <button onClick={handleUpdate} className="mt-2 bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded-lg">Salvare</button>
    </div>
  );
}

export default UserInfo;