import { useEffect, useState, useRef } from 'react';
import { db, auth, httpsCallable, functions } from '../firebase'; // adjust the path to your firebase.js file
import { collection, onSnapshot, getDocs } from 'firebase/firestore';
import ClientEditModal from './dashboardClientEdit';
import DashboardClientsAddClient from './dashboardClientsAddClient';

const DashboardClients2 = () => {
    const [clients, setClients] = useState([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const handleClose = () => {
        setShowModal(null);
        setRefreshKey(prevKey => prevKey + 1);
      };
    const clientsStateRef = useRef([]);
    useEffect(() => {
        const userId = auth.currentUser.uid;
        const clientsFirestoreRef = collection(db, 'customers', userId, 'clients');

        localStorage.removeItem('clients');

        const unsubscribe = onSnapshot(clientsFirestoreRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                const clientData = { id: change.doc.id, ...change.doc.data() };

                if (change.type === "added") {
                    if (!clientsStateRef.current.some(client => client.id === clientData.id)) {
                        clientsStateRef.current.push(clientData);
                    }
                }
                if (change.type === "removed") {
                    clientsStateRef.current = clientsStateRef.current.filter(client => client.id !== clientData.id);
                }
                if (change.type === "modified") {
                    const index = clientsStateRef.current.findIndex(client => client.id === clientData.id);
                    if (index !== -1) {
                        clientsStateRef.current[index] = clientData;
                    }
                }

                setClients([...clientsStateRef.current]);
                localStorage.setItem('clients', JSON.stringify(clientsStateRef.current));
            });
        });

        return () => unsubscribe();
    }, [refreshKey]);
    const [showModal, setShowModal] = useState(null);

const [isModalOpen, setIsModalOpen] = useState(false);

const deleteClient = async (clientId, firstName, lastName) => {
    if (!clientId) return;

    const confirmation = window.confirm(`Ești sigur că vrei să ștergi clientul ${firstName} ${lastName}?`);
    if (!confirmation) {
        return;
    }

    const data = {
        clientID: clientId,
    }

    try {
        const clientOperations = httpsCallable(functions, 'clientOperations');
        const result = await clientOperations({ operation: 'delete', data: data });
        console.log(result.data);
    
        console.log(`Client with id ${clientId} has been deleted.`);

        const userId = auth.currentUser.uid;
        const clientsFirestoreRef = collection(db, 'customers', userId, 'clients');
        const snapshot = await getDocs(clientsFirestoreRef);
        const newClients = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        clientsStateRef.current = newClients;
        setClients(newClients);
        localStorage.setItem('clients', JSON.stringify(newClients));

    } catch (error) {
        console.error('Error deleting client: ', error);
    }
};

    
    return (
        <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900 p-4 ">
        <div>
          <div className="p-4 rounded-t-lg bg-white block sm:flex items-center justify-between border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
            <div className="w-full mb-1">
              <div className="mb-4 flex justify-between ">
                <h1 className="text-xl font-semibold text-gray-800 sm:text-2xl dark:text-white">Clienți</h1>
                <button
        type="button"
        data-modal-toggle="add-user-modal"
        className="inline-flex items-center justify-center fit px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 sm:w-auto dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
        onClick={() => setIsModalOpen(true)}
      >
        <svg className="w-5 h-5 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        Client
      </button>
                    <DashboardClientsAddClient isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} refreshKey={refreshKey} setRefreshKey={setRefreshKey}/>

              </div>
                <div className="flex flex-wrap  border-t ">
                    {clients.map((client, index) => (
                        <div key={index} className="flex w-full dark:bg-gray-800 border-b justify-between xs:justify-between">
                            <div className="w-1/3 py-2">
                            <dl className="">
                            <div className="py-1 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                <dd className="mt-1 text-xl font-bold text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white ">
                                {client.clientType === "Persoana Juridica" ? client.denumireOficiala : `${client.firstName} ${client.lastName}`}
                                </dd>
                            </div>
                            {client.clientType === "Persoana Juridica" && (
                                <div className=" py-2 sm:grid sm:grid-cols-3 sm:gap-4">
                                <dd className="mt-1 text text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white">
                                {client.firstName} {client.lastName}
                                </dd>
                                </div>
                            )}
                            <div className=" py-1 sm:grid sm:grid-cols-3 sm:gap-4 ">
                                <dd className="mt-1 text font-semibold text-gray-900 sm:mt-0 sm:col-span-2 dark:text-white">
                                {client.phone}
                                </dd>
                            </div>
                           
                            </dl>
                            </div>
                            <div className="w-1/2 p-2  xs:hidden md:hidden self-center">
                                <dl className="flex justify-evenly ">
                                            <div className="px-1s py-2 flex flex-col ">
                                                <h3 className="font-medium text-gray-500 dark:text-white capitalize">Mașini: {client.metrics.nrMasini}</h3>
                                                <h3 className="font-medium text-gray-500 dark:text-white capitalize">Programări: {client.metrics.programari}</h3>
                                            </div>
                                            <div className="px-1s py-2 flex flex-col">
                                                <h3 className="font-medium capitalize text-green-500 dark:text-green-500">Confirmate: {client.metrics.confirmate}</h3>
                                                <h3 className="font-medium text-red-500 dark:text-red-500 capitalize">Refuzate: {client.metrics.refuzate}</h3>
                                            </div>
                                </dl>
                            </div>
                            <div className="w-fit flex justify-center items-center -mr-4 md:mr-0 xs:flex-col md:flex-col md:gap-4 xs:gap-4 md:items-end xs:items-end">
                            <button
                            type="button"
                            data-modal-toggle="add-user-modal"
                            className="mr-4 md:mr-0 inline-flex items-center justify-center w-fit px-3 xs:mr-0 py-2 text-sm font-medium text-center text-white rounded-lg bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 sm:w-auto dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                            onClick={() => deleteClient(client.id, client.firstName, client.lastName)}
                            refreshKey={refreshKey} setRefreshKey={setRefreshKey}
                            >
                            <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                            </svg>
                            Șterge
                        </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setShowModal(client.id);
                                    
                                }}
                                data-modal-toggle="add-user-modal"
                                className="mr-4 xs:mr-0 inline-flex md:mr-0 items-center justify-center w-fit px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 sm:w-auto dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                            >
                                <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-8 8a1 1 0 01-.683.267H6a1 1 0 01-1-1V9.97a1 1 0 01.267-.684l8-8zM9 11h2v2H9v-2zm-1-8h2v2H8V3z" clipRule="evenodd"></path>
                                </svg>
                                Vizualizare
                            </button>

                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </div>
            <ClientEditModal clientId={showModal} show={showModal !== null} onClose={handleClose} />

        </div>

        </div>
        
    );
}

export default DashboardClients2;