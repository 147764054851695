import React, { useState } from "react";

const DashboardSupport = () => {
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
    };

    return (
        <div
            id="main-content"
            className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900"
        >
            <main>
                <div className="p-4">
                    <form
                        onSubmit={handleSubmit}
                        className="p-4 center rounded-t-lg bg-white block items-center justify-between border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                    >
                        <div className="mb-4">
                            <label
                                htmlFor="subject"
                                className="block text-sm font-bold text-gray-700 dark:text-white"
                            >
                                Subiect
                            </label>
                            <input
                                type="text"
                                id="subject"
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                className="mt-1 p-2 block rounded-md w-1/2"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="description"
                                className="block text-sm font-bold text-gray-700 dark:text-white"
                            >
                                Descriere
                            </label>
                            <textarea
                                id="description"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                className="mt-1 p-2 block w-1/2 rounded-md"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-purple-700 hover:bg-purple-800"
                        >
                            Trimite
                        </button>
                         (
                            <p className="text-green-600 mt-2">Ticketul a fost trimis cu succes!</p>
                        
                    </form>
                </div>
            </main>
        </div>
    );
};

export default DashboardSupport;
