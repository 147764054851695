import React, { useState, useEffect} from "react";
import { auth, firestore, functions, httpsCallable} from '../firebase';
import { collection, getDocs, doc, deleteDoc, setDoc, getDoc } from 'firebase/firestore';
import InterventionModal from "./interventions";
import AddCar from "./addCar";
import UserCars from './UserCars';
import InfoCuiClient from "./infoCuiClient";
import carList from './car-list.json';


const NewClientForm = () => {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const clientType = event.target.elements.clientType.value;
    const firstName = event.target.elements['first-name'].value;
    const lastName = event.target.elements['last-name'].value;
    const cnpcui = clientType === 'Persoana Juridica' ? event.target.elements["cnpcui"].value : null;
    const phone = event.target.elements.phone1.value;
    const model = event.target.elements.model.value;
    const marca = event.target.elements.marca.value;
    const description = marca + " " + model;
    const position = event.target.elements.position1.value;
    const email = event.target.elements.email.value;

    const clientData = {
      email,
      firstName,
      lastName,
      phone,
      clientType,
      adresaOficiala: clientType === 'Persoana Juridica' ? adresaOficiala : null,
      denumireOficiala: clientType === 'Persoana Juridica' ? fetchedData : null,
    };

    if (clientType === 'Persoana Juridica') {
      clientData.cnpcui = cnpcui;
    }

    const carData = {
      position,
      description,
    };

    const data = {
      clientData,
      carData,
    };

    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations(data);
      console.log(result.data);
      alert("Programarea a fost adaugata cu succes!");
      window.location.reload();
    } catch (error) {
      console.error("Error calling clientOperations function: ", error);
    }
  };


  const [clientType, setClientType] = useState('Persoana Fizica');
  const [inputValue, setInputValue] = useState('');
  const [selectedBrand, setSelectedBrand] = useState(null);

  const filteredCarList = carList.filter(car => 
    car.brand.toLowerCase().startsWith(inputValue.toLowerCase())
  );

  const handleBrandChange = e => {
    setInputValue(e.target.value);
    setSelectedBrand(carList.find(car => car.brand === e.target.value));
  };
  const [cnpcui, setCnpCui] = useState('');
  const [fetchedData, setFetchedData] = useState('');
  const adresaOficiala = useState('');
  const fetchData = async () => {
    if (cnpcui) {
        try {
            let cui = cnpcui;

            const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "cui": cui })
            });

            if (!response.ok) {
              alert('CUI-ul introdus nu este valid');

                throw new Error('Network response was not ok');

            }

            const data = await response.json();
            data.adresaOficiala = data.address;
            setFetchedData(data.denumire);
        } catch (error) {
            console.error('Error:', error);
        }
    }
  };
  return (
    <div id="newClientForm">
      <div className="items-center p-4 border-gray-200 rounded-b dark:border-gray-700">
      <form id="addUsersForm" onSubmit={handleSubmit}>
              <div className="grid grid-cols-6 gap-6 pb-4">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="clientType" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                    Tip
                    <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                  </label>
                  <select name="clientType" id="clientType1" onChange={(e) => setClientType(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required>
                  <option value="Persoana Fizica">Persoana Fizica</option>
                    <option value="Persoana Juridica">Persoana Juridica</option>
                  </select>
                </div>
                {clientType === 'Persoana Juridica' && (
                <div className="col-span-6 sm:col-span-3">
                <label htmlFor="cnpcui" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">CUI <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                <input type="text" name="cnpcui" id="cnpcui" onBlur={fetchData} onChange={(e) => setCnpCui(e.target.value)} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="RO12345678" required />
              </div>
                )}
                {clientType === 'Persoana Juridica' && (
                  <div className="col-span-6 sm:col-span-3">
                    <label htmlFor="den-firnma" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                      Denumire Firma
                      <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                    </label>
                    <input type="text" name="den-firma" id="denfirma" value={fetchedData} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Auto" required />
                  </div>
                )}
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="first-name" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                    {clientType === 'Persoana Juridica' ? 'Prenume Reprezentant' : 'Prenume'}
                    <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                  </label>
                  <input type="text" name="first-name" id="firstName1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Ion" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">
                  {clientType === 'Persoana Juridica' ? 'Nume Reprezentant' : 'Nume'}
                  <span className="text-white text-xs opacity-50"> * obligatoriu</span>
                    </label>
                  <input type="text" name="last-name" id="lastName1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Popescu" required />
                </div>
               
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Telefon <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  
                  <input type="text" name="phone1" id="phone1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="0712345678" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="text" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="adresa@exemplu.ro" />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="position" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Nr. Înmatriculare <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  <input type="text" name="position" id="position1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="B000EXE" required />
                </div>
                <div className="col-span-6 sm:col-span-3 ">
                  <label htmlFor="marca" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Marcă <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
                  <input 
                  id="marca" 
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                  placeholder="Renault"
                  value={inputValue}
                  onChange={handleBrandChange}
                  list="car-brands"
                  required
                />
                <datalist id="car-brands">
                  {filteredCarList.map((car, index) => (
                    <option key={index} value={car.brand} />
                  ))}
                </datalist>
               </div>
               <div className="col-span-6 sm:col-span-3">
                          <>
              <label htmlFor="model" className=" mb-2 text-sm font-medium text-gray-900 dark:text-white flex justify-between">Model <span className="text-white text-xs opacity-50"> * obligatoriu</span></label>
              <input 
                id="model" 
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                placeholder="Megane"
                list="car-models"
                required
              />
              <datalist id="car-models">
                {selectedBrand && selectedBrand.models.map((model, index) => (
                  <option key={index} value={model} />
                ))}
              </datalist>
            </>
              </div>
              </div>
              <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                <button  className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>
              </div>
            </form>
      </div>
    </div>
  );
};



const DashboardClients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const setSelectedOption = useState('CurrentClient');
  const [refresh, setRefresh] = useState(false);
  const [isInterventionModalOpen, setIsInterventionModalOpen] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  const [isAddCarModalOpen, setIsAddCarModalOpen] = useState(false);

  
  const handleAddUser = () => {
    setIsAddUserModalOpen(true);
  };

  const handleAddUserClose = () => {
    setIsAddUserModalOpen(false);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsAddUserModalOpen(false);
  };

  const handleOutsideClick = (e) => {
    if (e.target.id === 'add-user-modal') {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isModalOpen]);

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const user = auth.currentUser;
      if (user) {
        const clientCollection = collection(firestore, "customers", user.uid, "clients");
        const clientSnapshot = await getDocs(clientCollection);
        const clientList = await Promise.all(clientSnapshot.docs.map(async doc => {
          const id = doc.id;
          const data = doc.data();
  
          const detailsCollection = collection(firestore, "customers", user.uid, "clients", id, "car");
          const detailsSnapshot = await getDocs(detailsCollection);
          const detailsData = detailsSnapshot.docs.map(doc => doc.data());
  
          return { id, ...data, details: detailsData };
        }));
  
        setClients(clientList);
      }
    };
  
    fetchClients();
  }, []);

  
  const [typeFilter, setTypeFilter] = useState('');
  const handleTypeFilterChange = (e) => setTypeFilter(e.target.value);
  const uniqueTypes = [...new Set(clients.map(client => client.clientType))];
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = event => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const newFilteredClients = clients.filter(client => {

      const matchesType = !typeFilter || client.clientType === typeFilter;

    const matchesSearchTerm = !searchTerm || (client.firstName && client.lastName && (client.firstName + ' ' + client.lastName).toLowerCase().includes(searchTerm.toLowerCase()));
      return matchesType && matchesSearchTerm;
    });

    setFilteredClients(newFilteredClients);
  }, [clients, typeFilter, searchTerm]);


  const handleDelete = async (id, firstName, lastName) => {
    const confirmation = window.confirm(`Sigur vrei să ștergi clientul ${firstName} ${lastName}? În cazul în care clientul are mașini adăugate sau programări făcute, acestea vor fi șterse!`);    if (!confirmation) {
      return;
    }
  
    const user = auth.currentUser;
    if (user && user.uid && id) {
      const clientRef = doc(firestore, "customers", user.uid, "clients", id);
      await deleteDoc(clientRef);
      setClients(clients.filter(client => client.id !== id));
  
      setIsModalOpen(false);
    }
  };
  const [client, setClient] = useState(null);
  const user = auth.currentUser;
  const [editingClientId, setEditingClientId] = useState(null);

  const handleEdit = async (clientId) => {
    setIsModalOpen(true);
    console.log(`Editing client with ID: ${clientId}`);
    setEditingClientId(clientId);
    const docRef = doc(firestore, 'customers', user.uid, 'clients', clientId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setClient(docSnap.data());
    } else {
      setClient({});
    }
  };
  console.log(client);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const docRef = doc(firestore, 'customers', user.uid, 'clients', editingClientId);
    await setDoc(docRef, client);
    setRefresh(!refresh); 
    setIsModalOpen(false)
    
  };
  const handleClose = () => {
    setIsModalOpen(false);

    };

    const handleIntervention = (clientId) => {
      console.log(`handleIntervention called with clientId: ${clientId}`);
      setIsInterventionModalOpen(true);
      setCurrentClientId(clientId);

      console.log(`isInterventionModalOpen after setting: ${isInterventionModalOpen}`);
    };
    const onClose = () => {
      console.log('handleInterventionModalClose called');
      setIsInterventionModalOpen(false);
    };

    const handleAddCar = (clientId) => {
      setIsAddCarModalOpen(true);
      setCurrentClientId(clientId);
    }
    const handleAddCarClose = () => {
      setIsAddCarModalOpen(false);
    }
    const [cars, setCars] = useState([]);
    const [showCars, setShowCars] = useState(false);
    const fetchClientCars = async (clientId) => {
      const user = auth.currentUser;
      if (user) {
        const carsRef = collection(firestore, 'customers', user.uid, 'clients', clientId, 'car');
        const carsSnap = await getDocs(carsRef);
        const carsData = carsSnap.docs.map(doc => doc.data());
        console.log('carsData:', carsData);
        setCars(carsData);
      }
    };

    const [isClicked, setIsClicked] = useState(false);

    return (
     <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900 p-4 ">
  <div>
    <div className="p-4 rounded-t-lg bg-white block sm:flex items-center justify-between border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="w-full mb-1">
        <div className="mb-4">
          <h1 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">Clienți</h1>
        </div>
        <div className="sm:flex">
          <div className="items-center hidden mb-3 sm:flex sm:divide-x sm:divide-gray-100 sm:mb-0 dark:divide-gray-700">
          <form className="lg:pr-3" action="#" method="GET">
      <label htmlFor="users-search" className="sr-only">Caută client</label>
      <div className="relative mt-1 lg:w-64 xl:w-96">
        <input 
          type="text" 
          name="email" 
          id="users-search" 
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
          placeholder="Caută client" 
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>
    </form>

          </div>
          <div className="flex items-center ml-auto space-x-2 sm:space-x-3">
          <button
        type="button"
        data-modal-toggle="add-user-modal"
        className="inline-flex items-center justify-center w-1/2 px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 sm:w-auto dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
        onClick={handleAddUser}
      >
        <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
        Adaugă client
      </button>

          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="overflow-hidden shadow">
            <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-600">
              <thead className="bg-gray-100 dark:bg-gray-700">
                <tr>
                  <th scope="col" className="p-4">
                    <div className="flex items-center opacity-0">
                      <input id="checkbox-all" aria-describedby="checkbox-1" type="checkbox" className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-purple-300 dark:focus:ring-purple-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600" />
                      <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                    </div>
                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Nume
                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Total <br/>programări

                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Programări <br/> confirmate / refuzate  
                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                  Intervenții
                  </th>
                  <th scope="col" className="p-2 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Tipul clientului <br/>
                    <select value={typeFilter} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" onChange={handleTypeFilterChange}>
                    <option value="" >Toate</option>
                    {uniqueTypes.map((clientType, index) => (
                      <option key={index} value={clientType}>{clientType}</option>
                    ))}
                  </select>
                  </th>
                  <th scope="col" className="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400">
                    Acțiuni
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700" id="searchResults">
              {filteredClients.length > 0 ? (
            filteredClients.map((client, index, clientType) => (
              <React.Fragment key={index}>
              <tr key={index}>
                      <td className="w-4 p-4">
                          <div className="flex items-center mt-2">
                        <button className="text-white font-bold" onClick={() => {
                            setShowCars(showCars === client.id ? null : client.id);
                            fetchClientCars(client.id);
                            setIsClicked(prevState => ({ ...prevState, [client.id]: !prevState[client.id] }));
                        }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className={`bi bi-arrow-down dark:text-white text-gray-900 ${isClicked[client.id] ? 'transform rotate-180' : ''}`} viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                        </svg>
                    </button>
                      </div>
            </td>
            <td className="flex items-center p-4 mr-12 space-x-6 whitespace-nowrap">
              <div className="text-sm font-normal text-gray-500 dark:text-gray-400">
                <div className="text-base font-semibold text-gray-900 dark:text-white">{client.firstName + " " + client.lastName}</div>
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">{client.cnpcui}</div>
                <div className="text-sm font-normal text-gray-500 dark:text-gray-400">{client.phone}</div>

              </div>
            </td>
    
            <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">  {client && client.metrics && client.metrics.programari}</td>
            <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">{client && client.metrics && client.metrics.confirmate + ' ' +'/' + ' '}{client && client.metrics && client.metrics.refuzate}</td>
            <td className="p-4 text-base font-medium text-gray-900 whitespace-nowrap dark:text-white">{client.interventionCount}</td>
            <td className="p-4 text-base font-normal text-gray-900 whitespace-nowrap dark:text-white">{client.clientType}</td>
            <td className="p-4 space-x-2 whitespace-nowrap">
            <button type="button" onClick={() => handleAddCar(client.id)} className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900"data-client-id={client.id} >
              <svg className="w-4 h-4 mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fillRule="evenodd" stroke="currentColor" strokeWidth="2" d="M12 22c-.552 0-1-.448-1-1v-8H3c-.552 0-1-.448-1-1s.448-1 1-1h8V3c0-.552.448-1 1-1s1 .448 1 1v8h8c.552 0 1 .448 1 1s-.448 1-1 1h-8v8c0 .552-.448 1-1 1z" clipRule="evenodd" />
              </svg>
                Mașină
              </button>
              <button type="button" className="editUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-900" data-client-id={client.id} onClick={() => handleEdit(client.id)}>
                <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M11.293 1.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-8 8a1 1 0 01-.683.267H6a1 1 0 01-1-1V9.97a1 1 0 01.267-.684l8-8zM9 11h2v2H9v-2zm-1-8h2v2H8V3z" clipRule="evenodd"></path>
                </svg>
                Editare client
              </button>

              <button type="button" className="deleteUserButton inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-600 rounded-lg hover:bg-red-800 focus:ring-4 focus:ring-red-300 dark:focus:ring-red-900" data-client-id={client.id} onClick={() => handleDelete(client.id, client.firstName, client.lastName)}>
                <svg className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd"></path>
                </svg>
                Șterge client
              </button>
            </td>
          </tr>
          {showCars === client.id && (
  <tr key={`user-cars-${index}`}>
    <td colSpan="7">
      <UserCars clientId={client.id} showCars={showCars} />
    </td>
  </tr>
)}
    </React.Fragment>
    ))
  ) : (
            <tr>
            <div className="flex items-center justify-center w-full  dark:text-white h-24">
              <h1>Nu aveti nici un client. Adaugă client cu butonul "Adaugă client"!</h1>
            </div>
          </tr>
          )}
          
      </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div className={`fixed left-0 right-0 z-50 items-center bg-slate-800/50 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0 h-modal sm:h-full ${isModalOpen ? '' : 'hidden'}`} id="editClientModal" aria-hidden="true">
      <div className="relative w-full h-full max-w-2xl md:h-auto pl-0 pr-0 mx-auto my-0 p-0 lg:mt-40 md:mt-40">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 lg:mt-40">
        {client?.clientType === 'Persoana Juridica' && (
  <div className="flex flex-col items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
    <div>
    <h3 className="text-xl font-semibold dark:text-white">
      Info client
    </h3>
    </div>
   
    <div className="flex mt-5">
    <InfoCuiClient clientId={editingClientId} />
                 </div>
  </div>
)}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Editează clientul
            </h3>
            <button onClick={handleClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" id="editModalClose">
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          {/* Modal body */}
          <div className="p-6 space-y-6">
          <form action="#" id="editClientform" onSubmit={async (event) => {
  event.preventDefault();
  // rest of your submit handler code
}}>
    <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="clientType" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tip</label>
                  <select name="clientType" value={client?.clientType} id="clientType1" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" required>
                    <option value>Select...</option>
                    <option value="Persoana Juridica">Persoana Juridica</option>
                    <option value="Persoana Fizica">Persoana Fizica</option>
                  </select>
                </div>
                <div className="col-span-6 sm:col-span-3">
                <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
  {client?.clientType === 'Persoana Juridica' ? 'Prenume Reprezentant' : 'Prenume'}
</label>
                  <input type="text" name="first-name" value={client?.firstName} id="firstName1" 
                  onChange={e => setClient({...client, firstName: e.target.value})}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Stefan" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  {client?.clientType === 'Persoana Juridica' ? 'Nume Reprezentant' : 'Prenume'}
</label>
                  <input type="text" name="last-name" value={client?.lastName || ''} id="lastName1" 
                  onChange={e => setClient({...client, lastName: e.target.value})}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Chetan" required />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telefon</label>
                  <input type="text" name="phone1" id="phone1" value={client?.phone} 
                  onChange={e => setClient({...client, phone: e.target.value})}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="e.g. 0742518064" required />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="text" name="email" id="email" value={client?.email} 
                  onChange={e => setClient({...client, email: e.target.value})}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="e.g. 0742518064" required />
                </div>

              </div>
              <div className="items-center p-6 border-t border-gray-200 rounded-b dark:border-gray-700 mt-5">
                <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit" onClick={handleSubmit}>Salvare</button>
              </div>
            </form>
         
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>
    {/* Add User Modal */}
    <div className={`fixed inline-flex left-0 right-0 z-50 items-center bg-slate-800/50 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0 h-modal sm:h-full ${isAddUserModalOpen ? '' : 'hidden'}`} id="addUserModal" aria-hidden="true">
      <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
        {/* Modal content */}
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          {/* Modal header */}
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Adaugă client
            </h3>
            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" onClick={handleAddUserClose}>
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
          </div>
          <div className="p-6 space-y-6">
          <NewClientForm />
          </div>
          {/* Modal footer */}
        </div>
      </div>
    </div>

    {/* Add intervention Modal*/}
    <AddCar isOpen={isAddCarModalOpen} onClose={() => setIsAddCarModalOpen(false)} clientId={currentClientId} />
    <InterventionModal isOpen={isInterventionModalOpen} onClose={onClose} clientId={currentClientId}  />

  </div>
</div>

    )
};

export default DashboardClients;