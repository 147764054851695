import React, { useState } from "react";
import CalendarContext  from './CalendarContext';
import Calendar2 from "./calendar2";

const DashboardCalendar2 = () => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);

    return (
        <CalendarContext.Provider value={{ selectedDate, setSelectedDate }}>
            <div id="main-content" className="relative w-full h-full p-4 overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
               
                <div key={refreshKey} className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
                        <Calendar2 setRefreshKey={setRefreshKey}/>
                    </div>
                    <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-3">
                </div>
            </div>
        </CalendarContext.Provider>
    );
};

export default DashboardCalendar2;