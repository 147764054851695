import React, { useState} from 'react';
import { firestore, auth } from '../firebase';
import { doc, getDoc, updateDoc} from 'firebase/firestore';


function AddCarItp({ isOpen, onClose, clientId, carId, userId, refreshParent }) {
  const [startDate, setStartDate] = useState(new Date().toISOString().substr(0, 10));
  const [endDate, setEndDate] = useState(new Date().toISOString().substr(0, 10));

  const handleSubmit = async (event) => {
      event.preventDefault();

      const userId = auth.currentUser.uid;
      if (!userId) {
        console.log('userId is undefined');
        return;
      }

      const clientRef = doc(firestore, 'customers', userId, 'clients', clientId);
      if (!clientRef) {
        console.log('clientRef is undefined');
        return;
      }

      const docSnap = await getDoc(clientRef);
      if (!docSnap.exists()) {
        console.log(`No document at path: customers/${userId}/clients/${clientId}`);
        return;
      }

      if (!carId) {
        console.log('carId is undefined');
        return;
      }

      const carRef = doc(clientRef, 'car', carId);
      const data = {
        startDate,
        endDate
      };

      await updateDoc(carRef, { itp: data });
      alert('Valabilitate ITP adaugata cu succes');
      onClose();
      refreshParent();
  };
    return (
        <div className={`fixed left-0 inline-flex right-0 z-50 items-center bg-slate-800/50 justify-center overflow-x-hidden overflow-y-auto top-0 md:inset-0 h-modal sm:h-full ${isOpen ? '' : 'hidden'}`} id="editClientModal" aria-hidden="true"> 
     <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
          <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
            <h3 className="text-xl font-semibold dark:text-white">
              Adaugă valabilitate ITP
            </h3>
            <button type="button" onClick={onClose} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" data-bs-dismiss="modal">     
                     <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
          <div className="p-6 space-y-6">
    <form id="addItp" onSubmit={handleSubmit}>
        <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 w-1/2">
                <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Din data:</label>
                <input type="date" id="startDate" name="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"  required />
            </div>

            <div className="col-span-6 w-1/2">
                <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pana in data:</label>
                <input type="date" id="endDate" name="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500"  required />          
            </div>

        </div>

        <div className="items-center pt-4 border-t mt-6 border-gray-200 rounded-b dark:border-gray-700">
            <button className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>
        </div>
    </form>
</div>
        </div>
      </div>
    </div>
    );

}

export default AddCarItp;
