import React from "react";
import { auth, functions} from "../firebase";
import { httpsCallable } from "firebase/functions";

const AddEmployee = ({ isAddEmployeeModalOpen, setIsAddEmployeeModalOpen }) => {

  const handleAddEmployee = async (event) => {
    event.preventDefault();

    const { currentUser } = auth;

    if (!currentUser) {
      console.error("No user is logged in.");
      return;
    }

    const employeeName = document.querySelector("#employeeName").value;
    const employeeLast = document.querySelector("#employeeLast").value;
    const employeeTag = employeeName[0] + employeeLast[0];

    const schimburi = document.querySelector("#schimburi").checked;
    const itp = document.querySelector("#itp").checked;
    const mecanica = document.querySelector("#mecanica").checked;
    const electrica = document.querySelector("#electrica").checked;
    const tinichigerie = document.querySelector("#tinichigerie").checked;
    const vulcanizare = document.querySelector("#vulcanizare").checked;
    const climatizare = document.querySelector("#climatizare").checked;
    const detailing = document.querySelector("#detailing").checked;
    const diagnosticare = document.querySelector("#diagnosticare").checked;
    const esapamente = document.querySelector("#esapamente").checked;

    const docData = {
      employeeTag: employeeTag,
      employeeName: employeeName,
      employeeLast: employeeLast,
      specializari: {
        schimburi,
        itp,
        mecanica,
        electrica,
        tinichigerie,
        vulcanizare,
        climatizare,
        detailing,
        diagnosticare,
        esapamente
      },
    };
    console.log(docData);

    try {
      const clientOperations = httpsCallable(functions, 'clientOperations');
      const result = await clientOperations({ operation: 'add', employeeData: docData });
      
      alert("Mecanicul a fost adaugat cu succes!");

      const newEmployee = { ...docData, id: result.data.id };
      const existingEmployees = JSON.parse(localStorage.getItem('employees')) || [];
      existingEmployees.push(newEmployee);
      localStorage.setItem('employees', JSON.stringify(existingEmployees));

      window.location.reload();
      setIsAddEmployeeModalOpen(false);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

    return (
      isAddEmployeeModalOpen && (
        <div className="modal fixed inline-flex bg-gray-800/80 dark:bg-slate-800/80 left-0 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full" id="add-employee-modal" aria-hidden="true">
        <div className="relative w-full h-full max-w-2xl px-4 md:h-auto">
          {/* Modal content */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            {/* Modal header */}
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
               Adaugă mecanic
              </h3>
              <button 
                type="button" 
                onClick={() => setIsAddEmployeeModalOpen(false)} // Use the setter function here
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white" 
                data-modal-toggle="add-user-modal"
              >
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="p-6 space-y-6">
            <form id="addEmployeeForm" onSubmit={handleAddEmployee}>
                <div className="grid grid-cols-6 gap-6 pb-4">
                  <div className="col-span-6 sm:col-span-3">
                    <span htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Nume</span>
                    <input type="text" name="first-name" id="employeeName" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Andrei" required />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <span htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Prenume</span>
                    <input type="text" name="last-name" id="employeeLast" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" placeholder="Ionescu" required />
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-3">
                <span className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Specializare</span>
                  <div className="flex space-x-4 justify-between mb-2">
                  <div className="space-y-2">
                  <label htmlFor="schimburi" className="flex items-center">
                    <input type="checkbox" id="schimburi" name="specializare" value="schimburi" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Schimburi</span>
                  </label>
                  <label htmlFor="itp" className="flex items-center">
                    <input type="checkbox" id="itp" name="specializare" value="itp" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">ITP</span>
                  </label>
                  <label htmlFor="mecanica" className="flex items-center">
                    <input type="checkbox" id="mecanica" name="specializare" value="mecanica" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Mecanica</span>
                  </label>
                  <label htmlFor="electrica" className="flex items-center">
                    <input type="checkbox" id="electrica" name="specializare" value="electrica" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Electrica</span>
                  </label>
                  <label htmlFor="tinichigerie" className="flex items-center">
                    <input type="checkbox" id="tinichigerie" name="specializare" value="tinichigerie" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Tinichigerie</span>
                  </label>
                
                  </div>
                  <div className="space-y-2">
                  <label htmlFor="vulcanizare" className="flex items-center">
                    <input type="checkbox" id="vulcanizare" name="specializare" value="vulcanizare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Vulcanizare</span>
                  </label>
                  <label htmlFor="climatizare" className="flex items-center">
                    <input type="checkbox" id="climatizare" name="specializare" value="climatizare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Climatizare</span>
                  </label>
                  <label htmlFor="detailing" className="flex items-center">
                    <input type="checkbox" id="detailing" name="specializare" value="detailing" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Detailing</span>
                  </label>
                  <label htmlFor="diagnosticare" className="flex items-center">
                    <input type="checkbox" id="diagnosticare" name="specializare" value="diagnosticare" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Diagnosticare</span>
                  </label>
                  <label htmlFor="esapamente" className="flex items-center">
                    <input type="checkbox" id="esapamente" name="specializare" value="esapamente" className="focus:ring-purple-500 h-4 w-4 text-purple-600 border-gray-300 rounded" />
                    <span className="ml-2 text-sm text-gray-900 dark:text-white">Esapamente</span>
                  </label>
                </div>
                </div>
              </div>
                <div className="items-center border-t border-gray-200 rounded-b dark:border-gray-700">
                <button className=" mt-6  text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800" type="submit">Adaugă</button>                </div>
              </form>
            </div>
            {/* Modal footer */}
          </div>
        </div>
      </div>

        )
    );
};

export default AddEmployee;