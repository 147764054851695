import React, { useState } from "react";
import UserInfo from "./userInfo";
import UserCredentials from "./userCredentials";
import UserHours from "./userHours";
import EmployeeList from "./employeeList";
import AddEmployee from "./addEmployee";
import DashboardMecanic from "./dashboardMecanic";


const DashboardSettings = () => {
  const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

    return (
            <div id="main-content" className="relative w-full h-full overflow-y-auto bg-gray-50 lg:ml-56 dark:bg-gray-900">
              <main>
                <div className="p-4">
                  <div className="grid gap-4 xl:grid-cols-2 2xl:grid-cols-4">
                    <div className="p-4 grid grid-cols-1 h-fit gap-4 bg-white border border-gray-200 rounded-lg shadow-sm 2xl:col-span-2 dark:border-gray-700 sm:p-6 dark:bg-gray-800 dark:text-white">
                      <div>
                        <UserInfo />
                      </div>
                      <div>
                        <UserCredentials />
                      </div>
                    </div>
                    <div className="p-4 bg-white border dark:text-white  xl:col-span-2 rounded-lg shadow-sm dark:border-gray-700 sm:p-6 dark:bg-gray-800">
                     <div>
                     <UserHours />
                     </div>
                     <div className=" dark:bg-slate-900 p-4 rounded-lg">
                     <h2 className="text-2xl font-bold mb-4">Mecanici</h2>
                      
                      <div className="w-1/2">
                      <EmployeeList/>
                      <AddEmployee isAddEmployeeModalOpen={isAddEmployeeModalOpen} setIsAddEmployeeModalOpen={setIsAddEmployeeModalOpen} />
                     
                     <div className="flex flex-row justify-between">
                     <button onClick={() => setIsAddEmployeeModalOpen(true)} type="button" data-modal-toggle="add-employee-modal" className=" flex-row flex mt-2 items-center bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded-lg">
                       <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                         <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                       </svg>
                       Adaugă mecanic
                     </button>
                     <div>
                      <button 
                        type="button" 
                        className="flex-row flex mt-2 items-center bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded-lg"
                        onClick={() => setIsModalOpen(true)}
                      >
                        <svg className="w-5 h-5 mr-2 -ml-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                        Creare cont
                      </button>

                    
                    </div>
                     </div>

                      </div>

                      </div>
                      <div className=" dark:bg-slate-900 p-4 mt-5 rounded-lg">
                     <h2 className="text-2xl font-bold mb-4">Cont Mecanic</h2>
                      
                      <div className="w-full">
                      {isModalOpen && <DashboardMecanic closeModal={() => setIsModalOpen(false)} />}

                      </div>

                      </div>
                    </div>
                    
                  </div>
                </div>
              </main>
              
            </div>
            
        );
    }

export default DashboardSettings;
