import React, { useState } from 'react';
import BasicNav from "../components/basicNav";
import { useNavigate } from 'react-router-dom';
import { auth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from '../firebase';
import { getFirestore, collection, query, where, getDocs, doc, setDoc, getDoc } from 'firebase/firestore';
import LoadingComp from '../components/loading';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

function LoginPage() {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userCui, setUserCui] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [userRole, setUserRole] = useState('');

  const toggleForm = () => {
    setIsRegister(!isRegister);
  };
  
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email.trim(), password);
      const user = userCredential.user;
      const db = getFirestore();

      const employeeRef = doc(db, "employeesLogin", user.uid);
      const employeeSnapshot = await getDoc(employeeRef);

      let role = '';
      if (employeeSnapshot.exists() && employeeSnapshot.data().role === 'employee') {
        role = 'employee';
        setUserRole('employee');
      }

      const q = query(collection(db, "customers", user.uid, "subscriptions"), where("role", "==", "premium"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        navigate('/dashboard');
      } else if (role === 'employee') {
        navigate('/employee');
      } else {
        navigate('/pricing');
      }
    } catch (error) {
      console.log("Email: ", email);
      console.error("Eroare la autentificare: ", error.message);
      console.error("Cod eroare: ", error.code);

      if (error.code === 'auth/wrong-password') {
        alert('Parola este incorectă. Vă rugăm să încercați din nou.');
        window.location.reload();
      } else if (error.code === 'auth/user-not-found') {
        alert('Nu a fost găsit niciun utilizator cu această adresă de e-mail. Vă rugăm să încercați din nou.');
        window.location.reload();
      } else {
        alert('A apărut o eroare. Vă rugăm să încercați din nou.');
        window.location.reload();
      }
    }
  };

  const handleRegister = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "cui": userCui })
    });

    if (!response.ok) {
      alert("Introduce un CUI valid");
      return;
    }

    const data = await response.json();
    console.log(data);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const db = getFirestore();
      const userDocRef = doc(db, 'customers', user.uid);
      await setDoc(userDocRef, {
        email: email,
        userName: userName,
        userPhone: userPhone,
        userCui: userCui,
        address: userAddress,
      });
      console.log(user);
      navigate('/pricing');
    } catch (error) {
      console.error("Error registering user: ", error.message);
      console.error("Error code: ", error.code);
    }
    setIsLoading(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isRegister) {
      await handleRegister(event);
    } else {
      await handleLogin(event);
    }
  };

  const handleForgotPassword = async () => {
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent');
      alert('S-a trimis linkul pentru resetarea parolei pe email!')
    } catch (error) {
      console.error('Error sending password reset email', error);
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="fixed top-0 w-full z-10">
        <BasicNav />
      </div>
      
      {isLoading ? <LoadingComp /> : (
        <section className="flex-grow mt-10 flex items-center justify-center bg-gray-50 dark:bg-gray-900">
          <div className="w-full max-w-md bg-white rounded-lg shadow dark:border dark:bg-gray-800 dark:border-gray-700 p-6 space-y-6">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              {isRegister ? "Creează un cont nou" : "Conectează-te"}
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Adresă de email
                </label>
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                  name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="adresa@exemplu.ro" required />
              </div>
              
              {isRegister && (
                <>
                  <div>
                    <label htmlFor="userName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Denumire Comercială
                    </label>
                    <input type="text" value={userName} onChange={(e) => setUserName(e.target.value)}
                      name="userName" id="userName" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Auto-Service SRL" required />
                    <span className="text-xs text-gray-900 dark:text-white">
                      Această denumire va fi folosită în e-mailurile și SMS-urile trimise.
                    </span>
                  </div>
                  <div>
                    <label htmlFor="userPhone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Nr. Telefon
                    </label>
                    <input type="tel" value={userPhone} onChange={(e) => setUserPhone(e.target.value)}
                      name="userPhone" id="userPhone" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="0712345678" required />
                  </div>
                  <div>
                    <label htmlFor="userAddress" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Adresa
                    </label>
                    <input type="text" value={userAddress} onChange={(e) => setUserAddress(e.target.value)}
                      name="userAddress" id="userAddress" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Str. Exemplu, Nr. 1, Oras, Judet" required />
                  </div>
                  <div>
                    <label htmlFor="userCui" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      CUI
                    </label>
                    <input type="text" value={userCui} onChange={(e) => setUserCui(e.target.value)}
                      name="userCui" id="userCui" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="12345678" required />
                  </div>
                  <div>
                    <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Confirmare Parolă
                    </label>
                    <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                      name="confirm-password" id="confirm-password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="••••••••" required />
                  </div>
                </>
              )}
              
              <div>
                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Parola
                </label>
                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                  name="password" id="password" placeholder="••••••••"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-600 focus:border-purple-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  required />
              </div>
              
              {!isRegister && (
                <div className="flex items-center justify-between">
                  <a onClick={handleForgotPassword} href="#" className="text-sm font-medium text-purple-600 hover:underline dark:text-purple-500">
                    Ai uitat parola?
                  </a>
                </div>
              )}
              
              <button type="submit" className="w-full text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800">
                {isRegister ? "Creează cont" : "Conectează-te"}
              </button>
              
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                {isRegister ? "Ai deja un cont?" : "Nu ai cont?"}
                <a href="#" onClick={toggleForm} className="font-medium text-purple-600 hover:underline dark:text-purple-500">
                  {isRegister ? " Conectează-te aici" : " Creează cont aici"}
                </a>
              </p>
            </form>
          </div>
        </section>
      )}
    </div>
  );
}

export default LoginPage;