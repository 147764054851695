import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import Logo from "./logo";
import { auth, firestore } from '../firebase'; // adjust the path according to your project structure
import { doc, onSnapshot } from 'firebase/firestore';
import ManageBillingButton from "./ManageBillingButton";
import LogoutButton from "./logoutButton";
import NotificationDropdown from "./notifications";
import ThemeSwitcher from "./ThemeSwitcher";
const DashboardNav = (props) => {

  const [profilePicture, setProfilePicture] = useState(localStorage.getItem('profilePicture') || '');

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);

      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if ('profilePicURL' in data) {
            const newProfilePicURL = data.profilePicURL;
            setProfilePicture(newProfilePicURL);
          } else {
            console.log('profilePicURL field does not exist in the document');
          }
        } else {
          console.log('Document does not exist');
        }
      }, (error) => {
        console.error('Error fetching document:', error);
      });
      return () => unsubscribe();
    } else {
      console.log('User is not logged in');
    }
  }, []);
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
  
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [darkMode, setDarkMode] = useState(() => {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    return isDarkMode;
  });

  useEffect(() => {
    document.documentElement.classList.toggle('dark', darkMode);
    localStorage.setItem('darkMode', String(darkMode));
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };
      return (
       <nav className="fixed z-30 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
  <div className="px-3 py-2 lg:px-5 lg:pl-3">
    <div className="flex items-center justify-between relative">
      <div className="flex items-center justify-start">
      <button id="toggleSidebarMobile" aria-expanded="true" aria-controls="sidebar" className="p-2 text-gray-600 rounded cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white" onClick={() => props.setIsSidebarOpen(!props.isSidebarOpen)}>
          <svg id="toggleSidebarMobileHamburger" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
          </svg>
        </button>
        <div  className="flex ml-2">
        <Logo width="60px" height="50px" darkMode={darkMode} />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Servicegest</span>
        </div>
      </div>
      <div className="flex items-center">
        <button id="toggleSidebarMobileSearch" type="button" className="p-2 hidden text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
          </svg>
        </button>
        <ThemeSwitcher darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
        <button type="button" data-dropdown-toggle="notification-dropdown" className="p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700" onClick={() => setIsNotificationOpen(!isNotificationOpen)}>
          <span className="sr-only">View notifications</span>
          <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z">
            </path>
          </svg>
        </button>
        <NotificationDropdown isOpen={isNotificationOpen} setIsOpen={setIsNotificationOpen} />
        
        <div className="flex items-center ml-3">
          <div>
          <button type="button" className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button-2" aria-expanded="false" data-dropdown-toggle="dropdown-2" onClick={() => setIsOpen(!isOpen)}>
          <span className="sr-only">Open user menu</span>
          <img className="w-8 h-8 rounded-full profilePicture" src={profilePicture} id="profilePicture" alt="user" />
        </button>
          </div>
          <div ref={dropdownRef} className={`z-50 absolute -right-5 px-2 top-10 text-base list-none bg-white divide-y divide-gray-100 rounded dark:bg-gray-700 dark:divide-gray-600 ${isOpen ? '' : 'hidden'}`} id="dropdown-2">
            <ul className="py-1" role="none">
              <li>
              <Link to="/dashboard/settings" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">
  Setări
</Link>              </li>
              <li>
              <ManageBillingButton />
              </li>
              <li>
              <LogoutButton />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
    
        );
    }

export default DashboardNav;