import React, { useEffect, useState } from 'react';
import { auth, firestore } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const SmartMetrics = () => {
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      const docRef = doc(firestore, 'customers', user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setMetrics(docSnap.data().metrics);
      } else {
        console.log('No such document!');
      }
    };

    fetchData();
  }, []);

  return (
    metrics && (
      <div>
        <p>Programări anulate: {metrics.anulate}</p>
        <p>Programări confirmate: {metrics.confirmate}</p>
        <p>Nr. de clienți: {metrics.nrClienti}</p>
        <p>Nr. de mașini: {metrics.nrMasini}</p>
        <p>Nr. de email-uri trimise: {metrics.nrEmails}</p>
        <p>Nr. de mesaje trimise: {metrics.nrMesaje}</p>
        <p>Nr. de programări: {metrics.programari}</p>
        <p>Vânzări: da</p>
      </div>
    )
  );
};

export default SmartMetrics;