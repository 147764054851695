import { useEffect, useState } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';

function InfoCuiClient({ clientId }) {
    const [cnpcui, setCnpcui] = useState('');
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [numeOficial, setNumeOficial] = useState('');
    const [adresaOficiala, setAdresaOficiala] = useState('');

    useEffect(() => {
        const fetchCnpcui = async () => {
            const user = auth.currentUser;

            if (user && clientId && typeof clientId !== 'object') {
                console.log(`Fetching document at customers/${user.uid}/clients/${clientId}`);
                const docRef = doc(firestore, `customers/${user.uid}/clients/${clientId}`);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    setCnpcui(docSnap.data().cnpcui);
                    console.log('Document data:', docSnap.data());
                } else {
                    console.log('No such document!');
                }
            } else {
                if (!user) {
                    console.log('User is not logged in!');
                }
                if (!clientId) {
                    console.log('Client ID is null!');
                }
                if (typeof clientId === 'object') {
                    console.log('Client ID is an object!');
                }
            }
        }

        fetchCnpcui();
    }, [clientId]);

    useEffect(() => {
        const fetchClientData = async () => {
            const user = auth.currentUser;
            if (user && clientId) {
                const docRef = doc(firestore, `customers/${user.uid}/clients/${clientId}`);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const clientData = docSnap.data();
                    setNumeOficial(clientData.numeOficial || '');
                    setAdresaOficiala(clientData.adresaOficiala || '');
                }
            }
        }

        fetchClientData();
    }, [clientId]);

    useEffect(() => {
        const fetchAddressAndName = async () => {
            const cnpcuiToSend = (cnpcui.startsWith('RO') || cnpcui.startsWith('ro')) ? cnpcui.slice(2) : cnpcui;
            const response = await fetch('https://europe-central2-servicegest-8852e.cloudfunctions.net/getCui', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "cui": cnpcuiToSend })
            });
            const data = await response.json();
            setAddress(data.address || '');
            setName(data.denumire);

            const user = auth.currentUser;
            if (user && clientId) {
                const docRef = doc(firestore, `customers/${user.uid}/clients/${clientId}`);
                await setDoc(docRef, { adresaOficiala: data.address || '', numeOficial: data.denumire }, { merge: true });
            }
        }

        const fetchClientData = async () => {
            const user = auth.currentUser;
            if (user && clientId) {
                const docRef = doc(firestore, `customers/${user.uid}/clients/${clientId}`);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const clientData = docSnap.data();
                    setAddress(clientData.adresaOficiala || '');
                    setName(clientData.numeOficial || '');
                }
            }
        }

        if (cnpcui) {
            // Check if numeOficial and adresaOficiala exist in Firestore
            if (!numeOficial || !adresaOficiala) {
                fetchAddressAndName();
            }
        }

        fetchClientData();
    }, [cnpcui, clientId, numeOficial, adresaOficiala]);

    return (
        <div className="flex gap-4">
             <div className="">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    CUI
                </label>
                <input 
                    type="text" 
                    name="cnpcui" 
                    id="cnpcui" 
                    value={cnpcui} 
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                    placeholder="Chetan" 
                    required 
                />
            </div>
            <div className="">
                <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Nume Oficiala
                </label>
                <input 
                    type="text" 
                    name="name" 
                    id="name" 
                    value={numeOficial} 
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                    placeholder="Chetan" 
                    required 
                />
            </div>
            <div className="col-span-6 sm:col-span-2">
                <label htmlFor="address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Address Oficiala
                </label>
                <input 
                    type="text" 
                    name="address" 
                    id="address" 
                    value={adresaOficiala} 
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500" 
                    placeholder="Chetan" 
                    required 
                />
            </div>
        </div>
    );
}

export default InfoCuiClient;
