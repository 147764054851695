import React, { useState, useEffect } from 'react';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { auth, firestore } from '../firebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

function UserInfo() {
  const [userName, setUserName] = useState('');
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicURL, setProfilePicURL] = useState('');

  useEffect(() => {
    const user = auth.currentUser;

    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          setUserName(doc.data().userName);
          setProfilePicURL(doc.data().profilePicURL);
        }
      });
      return () => unsubscribe();
    }
  }, []);

  const handleProfilePicChange = (e) => {
    setProfilePic(e.target.files[0]);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;

    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      const storage = getStorage();
      const storageRef = ref(storage, `profilePics/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, profilePic);

      uploadTask.on('state_changed', 
        (snapshot) => {
          console.log("File uploading...");
        }, 
        (error) => {
          console.error("Error uploading file: ", error);
        }, 
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setProfilePicURL(downloadURL);
            try {
              await updateDoc(userRef, {
                userName: userName,
                profilePicURL: downloadURL
              });
              console.log("User info successfully updated!");
            } catch (error) {
              console.error("Error updating user info: ", error);
            }
          });
        }
      );
    }
  };

  return (
    <div className="flex flex-col items-start justify-between mb-4 rounded-lg p-4 dark:bg-slate-900" id="userDetails">
      <h2 className="text-2xl font-bold mb-4">Detalii utilizator</h2>
      <img id="profilePicture" className="profilePicture mb-4 h-32 w-32 rounded-full self-center" src={profilePicURL} alt="Profile " />
      <p className="mb-2"><span className="font-bold">Nume:</span> <span>{userName}</span></p>
      <p className="mb-2"><span className="font-bold">Email:</span> <span>{auth.currentUser ? auth.currentUser.email : ''}</span></p>      
      <form onSubmit={handleUpdate}>
        <div className="mb-2">
          <span htmlFor="displayNameInput" className="font-bold">Nume:</span>
          <input id="displayNameInput" type="text" value={userName} onChange={(e) => setUserName(e.target.value)} className="ml-2 border rounded p-1 dark:text-zinc-800 text-gray-900" />
        </div>
        <div className="mb-2">
          <span htmlFor="profilePictureInput" className="font-bold">Poză</span>
          <input id="profilePictureInput" type="file" onChange={handleProfilePicChange} className="ml-2 rounded-lg p-1 w-28" />
        </div>
        <button type="submit" className="mt-2 bg-purple-700 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded-lg">Salvare</button>
      </form>
    </div>
  );
}

export default UserInfo;